<nav (click)="helperService.showUserDropdown = false;">
  <div class="d-flex main-nav">
    <div class="firm-logo" routerLink="/fleet" (click)="fleetService.setCurrentFleetById()"></div>
    <div class="ml-auto">
      <div class="user-menu" stop-propagation>
        <button class="dropdown-btn center" (click)="toggleUserDropdown($event)"
                [ngClass]="{'opened' : showUserDropDown}">
          <div class="icon">
            <i class="fa fa-user-circle"></i>
          </div>
          <div class="label">
            {{userService?.userData?.userName}}
          </div>
          <i class="fa fa-caret-down"></i>
        </button>

        <ul class="elements" *ngIf="showUserDropDown">
          <li (click)="logout()">{{'HEADER.LOGOUT' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="flex second-nav">
  </div>
</nav>
