import { Component, OnDestroy, OnInit } from '@angular/core';
import { FleetService } from '../../shared/services/fleet.service';
import { Subscription } from 'rxjs';
import { IFleet } from '../../shared/interfaces/fleet.interfaces';
import { FleetRestService } from '../../shared/services/rest-services/fleet-rest.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fleet-info',
  templateUrl: './fleet-info.component.html'
})


export class FleetInfoComponent implements OnInit, OnDestroy {
  public isCollapsed = false;
  public closeResult: string;
  public currentFleet: IFleet;
  public fleetSubscription: Subscription;

  constructor(private fleetService: FleetService,
              private fleetRestService: FleetRestService,
              private modalService: NgbModal,
              private router: Router) {
  }

  ngOnInit() {
    this.fleetSubscription = this.fleetService.currentFleet.subscribe(
      data => this.currentFleet = data);
  }

  ngOnDestroy() {
    this.fleetSubscription.unsubscribe();
  }

  public deleteFleet(id: number) {
    this.fleetRestService.deleteFleet(id).subscribe(() => {
      this.router.navigate(['fleet']);
      this.fleetService.getFleets();
      this.fleetService.fleetId.next(null);
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed`;
    });
  }

}
