<form name="form" class="mt-3" (ngSubmit)="aftermarketForm.form.valid && saveAftermarketCalculation()" #aftermarketForm="ngForm">
  <div *ngIf="aftermarketCalculation" class="row no-gutters data-box">
    <div class="col-12 mb-3">
      <div class="row">
        <div class="col-12">
          <input type="checkbox" class="toggle toggle-primary" id="active"
                 [(ngModel)]="aftermarketCalculation.active" name="active">
          <label for="active">{{'AFTERMARKET.ACTIVE' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="dlnr" class="col-2 col-form-label">{{'AFTERMARKET.DLNR' | translate}}</label>
        <div class="col-10">
          <input id="dlnr" type="text" name="dlnr"
                 placeholder="{{'AFTERMARKET.ALL_DLNRS' | translate}}"
                 class="form-control" #name="ngModel"
                 [ngbTypeahead]="dataService.searchDlnr"
                 [container]="'body'"
                 (selectItem)="setDlnr($event)"
                 (change)="checkValidEntryDlnr($event)"
                 [ngClass]="{'is-invalid': !validDlnr }"
                 [resultFormatter]="helperService.formatterName"
                 [inputFormatter]="helperService.formatterName"
                 [editable]='false'
                 [(ngModel)]="_dlnr"/>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="genArt" class="col-2 col-form-label">{{'AFTERMARKET.GEN_ART' | translate}}</label>
        <div class="col-10">
          <input id="genArt" type="text" name="genArt"
                 class="form-control" #name="ngModel"
                 placeholder="{{'AFTERMARKET.ALL_ARTICLES' | translate}}"
                 [ngbTypeahead]="dataService.searchGenArt"
                 [container]="'body'"
                 (change)="checkValidEntryGenArt($event)"
                 [ngClass]="{'is-invalid': !validGenArt }"
                 (selectItem)="setGenArt($event)"
                 [editable]='false'
                 [resultFormatter]="helperService.formatterDescriptionWithId"
                 [inputFormatter]="helperService.formatterDescriptionWithId"
                 [(ngModel)]="_genArt"/>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="unit" class="col-2 col-form-label">{{'AFTERMARKET.UNIT' | translate}}</label>
        <div class="col-10">
          <select required type="text" name="unit" class="form-control" #name="ngModel" id="unit"
                  [(ngModel)]="aftermarketCalculation.unit">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option *ngFor="let unit of calculationUnits"
                    [value]="unit">{{'AFTERMARKET.UNITS.' + unit | translate | slice:3}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="quantity" class="col-2 col-form-label">{{'AFTERMARKET.QUANTITY' | translate}}</label>
        <div class="col-10">
          <input id="quantity" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="aftermarketCalculation.quantity"
                 step="1" min="0" name="quantity" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="quantity" class="col-2 col-form-label">{{'MATERIAL_CODES.MC' | translate}}</label>
        <div class="col-10">
          <input id="material-code" type="text" class="form-control" #name="ngModel"
                 [(ngModel)]="aftermarketCalculation.materialCode"
                 [disabled]="true"
                 name="material-code" >
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-primary font-weight-bold">
          {{'AFTERMARKET.FLEET' |translate}}:
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fOp2" class="col-2 col-form-label">{{'AFTERMARKET.OPERATION_TYPE' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="aftermarketCalculation.computationMap.Flotte.operation.split('_')[1]" name="fOp2"
                  id="fOp2">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option value="CHARGE">{{'AFTERMARKET.OPERATION_TYPES.CHARGE' | translate}}</option>
            <option value="DISCOUNT">{{'AFTERMARKET.OPERATION_TYPES.DISCOUNT' | translate}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fOp1" class="col-2 col-form-label">{{'AFTERMARKET.OPERATION' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="aftermarketCalculation.computationMap.Flotte.operation.split('_')[0]" name="fOp1"
                  id="fOp1">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option value="PERCENTAL">%</option>
            <option value="CONST">{{helperService.getCurrencySmbol() | currencySymbol}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fvalue" class="col-2 col-form-label">{{'AFTERMARKET.AMOUNT' | translate}}</label>
        <div class="col-10">
          <input id="fvalue" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="aftermarketCalculation.computationMap.Flotte.value"
                 pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="fvalue" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-primary font-weight-bold">
          {{'AFTERMARKET.GARAGE' |translate}}:
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="wOp2" class="col-2 col-form-label">{{'AFTERMARKET.OPERATION_TYPE' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="aftermarketCalculation.computationMap.Werkstatt.operation.split('_')[1]" name="wOp2"
                  id="wOp2">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option value="CHARGE">{{'AFTERMARKET.OPERATION_TYPES.CHARGE' | translate}}</option>
            <option value="DISCOUNT">{{'AFTERMARKET.OPERATION_TYPES.DISCOUNT' | translate}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="wOp1" class="col-2 col-form-label">{{'AFTERMARKET.OPERATION' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="aftermarketCalculation.computationMap.Werkstatt.operation.split('_')[0]" name="wOp1"
                  id="wOp1">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option value="PERCENTAL">%</option>
            <option value="CONST">{{helperService.getCurrencySmbol() | currencySymbol}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="gvalue" class="col-2 col-form-label">{{'AFTERMARKET.AMOUNT' | translate}}</label>
        <div class="col-10">
          <input id="gvalue" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="aftermarketCalculation.computationMap.Werkstatt.value"
                 pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="gvalue" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12  button-group">
          <button type="submit" [disabled]="!(aftermarketForm.form.valid && validDlnr && validGenArt)"
                  class="btn btn-primary float-left">{{'BTN.SAVE' | translate }}</button>
          <button type="button" (click)="helperService.locationBackfromMenu()"
                  class="btn btn-outline-secondary float-right">{{'BTN.CANCEL' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</form>
