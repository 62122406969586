<div class="mb-2 mt-3" *ngIf="currentFleet">
  <ngb-accordion #acc1="ngbAccordion">
    <ngb-panel>
      <ng-template ngbPanelHeader>
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><span
            class="mr-auto">{{'FLEET.INFORMATION' | translate}}</span></button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div>{{'FLEET.NAME' | translate}}
          <b>{{currentFleet?.fleetname}}</b></div>
        <div class="button-group">
          <button type="submit" class="btn btn-primary float-left"
                  [routerLink]="['/fleet/' + currentFleet.id + '/edit_fleet']">
            {{'BTN.EDIT' | translate }}</button>
          <!--
          <button type="submit" (click)="open(content)"
                  class="btn btn-danger float-right">{{'BTN.DELETE' | translate }}</button>
          -->
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>


<ng-template #content let-modal>
  <div class="confirm-modal">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'FLEET.DELETE_FLEET' | translate}}</h4>
      <button type="button" class="close no-outline" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary"
              (click)="modal.close('Save click')">{{'BTN.CANCEL' | translate}}</button>
      <button type="button" class="btn btn-danger"
              (click)="modal.close('Save click'); deleteFleet(currentFleet.id)">{{'BTN.DELETE_FLEET' | translate}}</button>
    </div>
  </div>
</ng-template>

