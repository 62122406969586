<div class="user-login-container">
  <div class="sidebar"></div>
  <div class="login-box">
    <div class="user-box">
      <h1><strong>{{'LOGIN.WELCOME_TITLE_1' | translate}}.</strong> {{'LOGIN.WELCOME_TITLE_2' | translate}}.</h1>
      <form #loginForm="ngForm">
        <div class="form-group row">
          <label for="name" class="col-4 col-form-label">{{'USER.USERNAME' | translate}}</label>
          <div class="col-8">
            <input id="name" type="text" name="name" class="form-control" #name="ngModel" [(ngModel)]="username"
                   required>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-4 col-form-label">{{'USER.PASSWORD' | translate}}</label>
          <div class="col-8">
            <input id="password" type="password" name="password" class="form-control" #name="ngModel"
                   [(ngModel)]="password"
                   required>
          </div>
        </div>
        <div class="btn-container">
          <button class="btn btn-primary" (click)="onSubmit($event, loginForm.valid)" [disabled]="!loginForm.valid">
            <div *ngIf="!authService.loadingState"><strong>{{'BTN.LOGIN' | translate}}</strong></div>
            <div *ngIf="authService.loadingState === 'PENDING'" class="loader"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
