import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {FleetService} from '../../shared/services/fleet.service';
import {ToastService} from '../../shared/services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../shared/services/user.service';
import {finalize} from "rxjs/operators";


@Injectable()
export class AuthService {
  public isLoggedIn = true;
  public loadingState: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private fleetService: FleetService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
    this.login();
  }


  public login(username?: string, password?: string, fromLogin?: boolean) {
    let headers: HttpHeaders;

    if (username && password) {
      headers = new HttpHeaders({Authorization: 'Basic ' + btoa(username + ':' + password)});
    }

    this.http.get(
      environment.internal_apiUrl + '/login'
      , {headers}
    ).pipe(finalize(() => {
      this.loadingState = undefined;
    })).subscribe(
      (response: any) => {
        this.userService.userData$.next(response);
        this.isLoggedIn = true;
        this.fleetService.getFleets();

        if (username && password) {
          this.router.navigate(['/fleet']);
        }
        return true;
      },
      () => {
        if (fromLogin) {
          this.toastService.show(this.translate.instant('ERROR.UNAUTHORIZED'), {
            classname: 'bg-danger text-light',
            delay: 5000
          });
        }
        this.isLoggedIn = false;
        return false;
      });

  }

  public logout(): Observable<any> {
    this.fleetService.reset();
    this.isLoggedIn = false;
    return this.http.get(
      environment.internal_apiUrl + '/logout'
    );
  }

}
