import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { LoadingService } from '../shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html'
})
export class RootComponent implements OnInit, AfterContentChecked {

  constructor(public authService: AuthService, public loadingService: LoadingService, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }
}
