import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CalculationService } from '../../../../shared/services/calculation.service';
import { FleetService } from '../../../../shared/services/fleet.service';
import { ICalculation } from '../../../../shared/interfaces/calculation.interface';
import { CalculationRestService } from '../../../../shared/services/rest-services/calculation-rest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {HelperService} from "../../../../shared/services/helper.service";

@Component({
  selector: 'app-aftermarket-calculation-info',
  templateUrl: './aftermarket-calculation-info.component.html'
})
export class AftermarketCalculationInfoComponent implements OnInit, OnDestroy {
  public isCollapsed = false;
  public closeResult: string;
  public currentAftermarketCalculation: ICalculation;
  public currentAftermarketCalculationSubscription: Subscription;

  constructor(public calculationService: CalculationService,
              private fleetService: FleetService,
              public helperService: HelperService,
              private calculationRestService: CalculationRestService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.currentAftermarketCalculationSubscription = this.calculationService.currentCalculation.subscribe(
      data => this.currentAftermarketCalculation = data);
  }

  ngOnDestroy() {
    this.currentAftermarketCalculationSubscription.unsubscribe();
  }

  public deleteCalculation(id: number) {
    this.calculationRestService.deleteCalculation(id).subscribe(data => {
      this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'AFTER_MARKET');
      this.calculationService.resetCalculationIds();
    });
  }

  public activeChanged(event) {
    this.calculationRestService.updateCalculation(this.currentAftermarketCalculation).subscribe(data => this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, this.fleetService.currentSubmenu.value));
  }

  public open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }
}
