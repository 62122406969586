<div class="mb-2" *ngIf="currentOeArticleCalculation && currentOeArticleCalculation.type === 'OE_PART'">
  <ngb-accordion #acc1="ngbAccordion">
    <ngb-panel>
      <ng-template ngbPanelHeader>
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><span
            class="mr-auto"> {{'CALCULATION.INFORMATION' | translate}}</span></button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <input type="checkbox" class="toggle toggle-primary" id="ac"
               [(ngModel)]="currentOeArticleCalculation.active" (ngModelChange)="activeChanged($event)">
        <label for="ac">{{'OE_PART.ACTIVE' | translate}}</label>
        <div><b>{{currentOeArticleCalculation.description}}</b></div>
        <div>
          {{'OE_PART.FLEET' | translate}}

          <i
            [ngClass]="{'ml-1': true,'n4icon-plus-add-outline': currentOeArticleCalculation.computationMap.Flotte.operation.includes('CHARGE'), 'n4icon-minus-outline': currentOeArticleCalculation.computationMap.Flotte.operation.includes('DISCOUNT')}"></i>
          <b>&nbsp; {{currentOeArticleCalculation.computationMap.Flotte.value}}
            <span> {{(currentOeArticleCalculation.computationMap.Flotte.operation.includes('PERCENTAL') ? '%' : (helperService.getCurrencySmbol() | currencySymbol))}}</span>
          </b>
        </div>
        <div>
          {{'OE_PART.GARAGE' | translate}}

          <i
            [ngClass]="{'ml-1': true,'n4icon-plus-add-outline': currentOeArticleCalculation.computationMap.Werkstatt.operation.includes('CHARGE'), 'n4icon-minus-outline': currentOeArticleCalculation.computationMap.Werkstatt.operation.includes('DISCOUNT')}"></i>
          <b>&nbsp; {{currentOeArticleCalculation.computationMap.Werkstatt.value}}
            <span> {{(currentOeArticleCalculation.computationMap.Werkstatt.operation.includes('PERCENTAL') ? '%' : (helperService.getCurrencySmbol() | currencySymbol))}}</span></b>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>


<ng-template #content let-modal>
  <div class="confirm-modal">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'OE_PART.DELETE_CALCULATION' | translate}}</h4>
      <button type="button" class="close no-outline" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary"
              (click)="modal.close('Save click')">{{'BTN.CANCEL' | translate}}</button>
      <button type="button" class="btn btn-danger"
              (click)="modal.close('Save click'); deleteCalculation(calculationService.calcIdId.value)">{{'BTN.DELETE_CALCULATION' | translate}}</button>
    </div>
  </div>
</ng-template>
