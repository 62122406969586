import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {MaterialCodesRestService} from "./rest-services/material-codes-rest.service";

@Injectable({
  providedIn: 'root'
})
export class MaterialCodesService {

  public materialCodes: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private materialCodesRestService: MaterialCodesRestService) {
  }


  public getMaterialCodesByFleet(fleetId: number) {
    this.materialCodesRestService.getMaterialCodesByFleet(fleetId).subscribe((response) => {
      if (response) {
        this.materialCodes.next(response);
      } else {
        this.materialCodes.next(null);
      }
    });
  }

}
