import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FleetService } from '../../shared/services/fleet.service';
import { IFleet } from '../../shared/interfaces/fleet.interfaces';
import { FleetRestService } from '../../shared/services/rest-services/fleet-rest.service';
import { HelperService } from '../../shared/services/helper.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-fleet',
  templateUrl: './new-fleet.component.html'
})
export class NewFleetComponent implements OnInit {
  public fleet: IFleet;
  public currentId: number;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private fleetService: FleetService,
              private fleetRestService: FleetRestService,
              public helperService: HelperService) {
    this.fleet = {} as IFleet;
  }

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.currentId = Number(params.fleetId);
      if (this.currentId > 0) {
        this.fleetService.setCurrentFleetById(this.currentId);
        this.fleetService.currentFleet.subscribe(data => this.fleet = data);
      }
    });
  }


  public saveFleet() {
    const request: Observable<IFleet> = this.currentId ? this.fleetRestService.updateFleet( this.fleet as IFleet) : this.fleetRestService.createFleet( this.fleet as IFleet);
    request.subscribe(() => {
      this.router.navigate(['fleet']);
      this.fleetService.getFleets();
    });
  }

}
