<form class="mt-3" name="form" (ngSubmit)="fleetForm.form.valid && saveFleet()" #fleetForm="ngForm">
  <div class="row no-gutters data-box" *ngIf="fleet">
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fleet-name" class="col-2 col-form-label">{{'FLEET.NAME' | translate}}</label>
        <div class="col-10">
          <input type="text" id="fleet-name" class="form-control" [(ngModel)]="fleet.fleetname" name="fleetname"
                 required/>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-12  button-group">
          <button type="submit"
                  class="btn btn-primary float-left">{{'BTN.SAVE' | translate }}</button>
          <button type="button" (click)="helperService.locationBackfromMenu()"
                  class="btn btn-outline-secondary float-right">{{'BTN.CANCEL' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</form>
