import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { IFleet } from '../interfaces/fleet.interfaces';
import { FleetRestService } from './rest-services/fleet-rest.service';
import { CalculationService } from './calculation.service';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class FleetService {
  public fleetId: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  public currentFleet: BehaviorSubject<IFleet> = new BehaviorSubject<IFleet>(undefined);
  public currentSubmenu: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public fleets: BehaviorSubject<IFleet[]> = new BehaviorSubject<IFleet[]>(undefined);
  public fleetSubmenu: string[];
  public highlightedCalculationId: BehaviorSubject<number>  = new BehaviorSubject<number>(undefined);
  public currentFleetId: number;

  constructor(private fleetRestService: FleetRestService,
              private calculationService: CalculationService) {

    this.fleetId.subscribe(id => {
      if (id) {
        this.fleetRestService.getFleetById(id).subscribe(fleet => {
          this.currentFleet.next(fleet);
        });
      } else {
        this.currentFleet.next(null);
      }
    });

    this.currentSubmenu.subscribe(() => {
      this.calculationService.resetCalculationIds();
    });

    this.fleetSubmenu = ['AFTER_MARKET',
      'OE_PART',
      'FIXED_PRICE',
      'HOURLY_WAGE',
    'MATERIAL_CODES'];
  }


  public reset() {
    this.currentFleet.next(null);
    this.currentSubmenu.next(null);
    this.fleetId.next(null);
    this.fleets.next(null);
  }


  public getFleets() {
    this.fleetRestService.getFleets().subscribe((response) => {
      this.fleets.next(response);
    });
  }


  public getFleetSubmenu(): Observable<string[]> {
    return of(this.fleetSubmenu);
  }


  public setCurrentFleetById(id?: any, force?: boolean) {
    if (this.currentFleetId !== id) {
      if (!this.fleets) {
        this.getFleets();
      }
      if (id) {
        this.fleetId.next(id);
        this.currentFleetId = id;
      } else {
        this.fleetId.next(null);
        this.currentFleetId = null;
      }
    }
  }


  public setCurrentSubmenu(menu?: string) {
    if (!menu) {
      this.currentSubmenu.next(null);
    } else {
      const submenu = menu.toLowerCase();
      if (this.fleetSubmenu.length) {
        if (submenu) {
          for (const item of this.fleetSubmenu) {
            if (item.toLowerCase() === submenu) {
              this.currentSubmenu.next(item);
              break;
            } else {
              this.currentSubmenu.next(null);
            }
          }
        }
      }
    }
  }

}
