import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IDlnr, IGenArt, IManufacturer } from '../../interfaces/data.interface';

@Injectable({
  providedIn: 'root'
})
export class DataRestService {

  constructor(private http: HttpClient) {
  }


  public getManufacturer(): Observable<IManufacturer[]> {
    return this.http.get<IManufacturer[]>(
      `${environment.internal_apiUrl}/data/manufacturer`
    );
  }

  public getDlnr(): Observable<IDlnr[]> {
    return this.http.get<IDlnr[]>(
      `${environment.internal_apiUrl}/data/dlnr`
    );
  }

  public getGenArts(): Observable<IGenArt[]> {
    return this.http.get<IGenArt[]>(
      `${environment.internal_apiUrl}/data/genArt`
    );
  }

  public getTree(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.internal_apiUrl}/data/tree`
    );
  }

}
