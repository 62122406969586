import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IHourlyWageCalculation } from '../../../../shared/interfaces/hourly-wage.interfaces';
import { CalculationService } from '../../../../shared/services/calculation.service';
import { FleetService } from '../../../../shared/services/fleet.service';
import {HelperService} from "../../../../shared/services/helper.service";

@Component({
  selector: 'app-hourly-wage-info',
  templateUrl: './hourly-wage-info.component.html'
})
export class HourlyWageInfoComponent implements OnInit, OnDestroy {
  public isCollapsed = false;
  public currentHourlyWageCalculation: IHourlyWageCalculation;
  public currentHourlyWageCalculationSubscription: Subscription;

  constructor(private calculationService: CalculationService,
              public helperService: HelperService,
              private fleetService: FleetService) {
  }

  ngOnInit() {
    this.currentHourlyWageCalculationSubscription = this.calculationService.currentHourlyWageCalculation.subscribe(
      data => this.currentHourlyWageCalculation = data);
  }

  ngOnDestroy() {
    this.currentHourlyWageCalculationSubscription.unsubscribe();
  }

}
