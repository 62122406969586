import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IUser} from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userData$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(undefined);
  public userData: IUser;

  constructor() {
    this.userData$.subscribe( response => this.userData= response);
  }
}
