<div class="mb-2" *ngIf="currentHourlyWageCalculation">
  <ngb-accordion #acc1="ngbAccordion">
    <ngb-panel>
      <ng-template ngbPanelHeader>
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><span
            class="mr-auto">{{'HOURLY_WAGE.WAGE_INFO' | translate}}
            : ({{'HOURLY_WAGE.PARTS.' + currentHourlyWageCalculation.type | translate}})</span></button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div>
          {{'FIXED_PRICE.FLEET' | translate}}
          <b>{{currentHourlyWageCalculation.amountDTOMap.Flotte.amount}}  {{(helperService.getCurrencySmbol() | currencySymbol)}}</b>
        </div>
        <div>
          {{'FIXED_PRICE.GARAGE' | translate}}
          <b>{{currentHourlyWageCalculation.amountDTOMap.Werkstatt.amount}}  {{(helperService.getCurrencySmbol() | currencySymbol)}}</b>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
