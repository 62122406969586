import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FleetService } from '../../../shared/services/fleet.service';
import { HelperService } from '../../../shared/services/helper.service';
import { DataService } from '../../../shared/services/data.service';
import { ISearchData } from '../../../shared/interfaces/data.interface';
import { CalculationRestService } from '../../../shared/services/rest-services/calculation-rest.service';


@Component({
  selector: 'app-find-calculation',
  templateUrl: './find-calculation.component.html'
})
export class FindCalculationComponent implements OnInit, OnDestroy {
  public isCollapsed = true;
  public currentSubmenu: string;
  public currentSubmenuSubscription: Subscription;
  public search: ISearchData;
  public _genArt;
  public _dlnr;
  public _manufacturer;
  public calculation;
  public calculationFound: boolean;


  constructor(private fleetService: FleetService,
              private calculationRestService: CalculationRestService,
              public dataService: DataService,
              public helperService: HelperService) {
    this.search = {} as ISearchData;
  }

  ngOnInit() {
    this.dataService.getGenArts();
    this.dataService.getDlnrs();
    this.dataService.getManufacturers();

    this.currentSubmenuSubscription = this.fleetService.currentSubmenu.subscribe(
      data => this.currentSubmenu = data);
  }

  ngOnDestroy() {
    this.currentSubmenuSubscription.unsubscribe();
  }

  public setGenArt(event) {
    this.search.genArt = event.item.id.toString();
  }

  public setDlnr(event) {
    this.search.brandName = event.item.id.toString();
  }

  public setManufacturer(event) {
    this.search.brandName = event.item.id.toString();
  }

  public searchForCalculation() {
    this.calculationRestService.findCalculation(this.fleetService.fleetId.value, this.currentSubmenu, this.search).subscribe(
      (data) => {
        if (!data) {
          this.calculation = [];
          this.calculationFound = false;
        } else {
          this.calculationFound = true;
          this.calculation = data;

          const element: HTMLElement | null = document.getElementById(data.amountDTOMap.Flotte.uniqueId);
          if(element && !this.isElementInViewport(element)) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center'});
            this.fleetService.highlightedCalculationId.next(data.amountDTOMap.Flotte.uniqueId);
          } else if (element) {
            this.fleetService.highlightedCalculationId.next(data.amountDTOMap.Flotte.uniqueId);
          }
        }
      });


  }

  public isElementInViewport(element) {
    if (element.style.display === 'none') return false
    var rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document. documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document. documentElement.clientWidth)
    );
  }

}
