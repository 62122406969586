import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  public username: string;
  public password: string;

  constructor(public authService: AuthService) {
  }

  ngOnInit(): void {
  }

  public onSubmit(event: Event, valid: boolean) {
    this.authService.loadingState = 'PENDING';
    event.preventDefault();
    if (valid) {
      this.authService.login(this.username, this.password,true);
    }
  }


}
