import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CalculationService} from '../../../../shared/services/calculation.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {ICalculation} from '../../../../shared/interfaces/calculation.interface';
import {CalculationRestService} from '../../../../shared/services/rest-services/calculation-rest.service';
import {DefenitionRestService} from '../../../../shared/services/rest-services/defenition-rest.service';
import {FleetService} from '../../../../shared/services/fleet.service';
import {DataService} from '../../../../shared/services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import {IGenArt, IManufacturer} from '../../../../shared/interfaces/data.interface';

@Component({
  selector: 'app-new-oe-article-calculation',
  templateUrl: './new-oe-article-calculation.component.html'
})
export class NewOeArticleCalculationComponent implements OnInit, OnDestroy {
  public currentId: number;
  public oeArticleCalculation: ICalculation;
  public calculationUnits: string[];
  public routeSubscription: Subscription;
  public calculationOperations: string[];
  public _genArt: IGenArt;
  public _manufacturer: IManufacturer;
  public validGenArt = true;
  public validManufacturer = true;

  constructor(private route: ActivatedRoute,
              private calculationService: CalculationService,
              private calculationRestService: CalculationRestService,
              private defenitionRestService: DefenitionRestService,
              public dataService: DataService,
              private fleetService: FleetService,
              private router: Router,
              private translate: TranslateService,
              public helperService: HelperService) {


    this.oeArticleCalculation = {
      id: null,
      type: 'OE_PART',
      sortNo: 1,
      fleetId: null,
      description: '',
      active: true,
      unit: '',
      materialCode: '',
      quantity: null,
      conditions: [ ],
      computationMap: {
        Flotte: {
          operation: '',
          value: null
        },
        Werkstatt: {
          operation: '',
          value: null
        }
      }
    };

  }

  ngOnInit() {
    this.setFleedId();
    this.getData();
    this.getDefinitions();

    this.routeSubscription = this.route.parent.params.subscribe((params) => {
      this.currentId = Number(params.calcId);
      if (this.currentId > 0) {
        this.calculationService.setCurrentCalculationById(this.currentId);
        this.calculationService.currentCalculation.pipe(debounceTime(500), take(1)).subscribe(data => {
          this.oeArticleCalculation = data;
          this.setConditionsToModel();
          this.checkOperations();
        });
      }
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }


  public setConditionsToModel() {
    this.oeArticleCalculation.conditions.forEach((condition, i) => {
      if (condition.conditionField === "GEN_ART") {
        const temp = this.dataService.genArts[this.dataService.genArts.findIndex(item => item.id === parseInt(String(condition.value)))];
        const description = temp ? temp.description : '';
        this._genArt = {
          id: Number(this.oeArticleCalculation.conditions[i].value),
          description: description
        };
      }
      if (condition.conditionField === "OE_SUPPLIER") {
        const temp = this.dataService.dlnrs[this.dataService.dlnrs.findIndex(item => item.id === parseInt(String(condition.value)))];
        const name = temp ? temp.name : '';
        this._manufacturer = {
          id: Number(this.oeArticleCalculation.conditions[i].value),
          name: name
        };
      }
    });
  }

  public setConditionsFromModel() {
    if (this.oeArticleCalculation && this.oeArticleCalculation.conditions) {
      if (this._manufacturer) {
        this.conditionExists('OE_SUPPLIER') ?
          this.oeArticleCalculation.conditions[this.searchIndex('OE_SUPPLIER')].value = this._manufacturer.id.toString() :
          this.oeArticleCalculation.conditions.push({
            sortNo: 1,
            conditionField: 'OE_SUPPLIER',
            value: this._manufacturer.id.toString()
          });
      } else {
        this.oeArticleCalculation.conditions.splice(this.searchIndex('OE_SUPPLIER'), 1);
      }
      if (this._genArt) {
        this.conditionExists('GEN_ART') ?
          this.oeArticleCalculation.conditions[this.searchIndex('GEN_ART')].value = this._genArt.id.toString() :
          this.oeArticleCalculation.conditions.push({
            sortNo: 2,
            conditionField: 'GEN_ART',
            value: this._genArt.id.toString()
          });
      } else {
        this.oeArticleCalculation.conditions.splice(this.searchIndex('GEN_ART'), 1);
      }
    }
  }


  public setFleedId() {
    this.oeArticleCalculation.fleetId = this.fleetService.fleetId.value;
  }


  public getDefinitions() {
    this.defenitionRestService.getDefenitionsByMethodeForType('calculation_operations', 'OE_PART').subscribe(data => this.calculationOperations = data);
    this.defenitionRestService.getDefenitionsByMethodeForType('calculation_units', 'OE_PART').subscribe(data => this.calculationUnits = data);
  }

  public getData() {
    this.dataService.getGenArts();
    this.dataService.getManufacturers();
  }

  public setGenArt(event) {
    this.validGenArt = true;
    this._genArt = event.item;
  }

  public setManufacturer(event) {
    this.validManufacturer = true;
    this._manufacturer = event.item;
  }


  public checkValidEntryGenArt(e) {
    const userInput = e.target.value;
    if (!this.dataService.genArts.some(item => userInput.includes(item.description))) {
      this.validGenArt = false;
    }
    if (userInput === '') {
      this.validGenArt = true;
    }
  }

  public checkValidEntryManufacturer(e) {
    const userInput = e.target.value;
    if (!this.dataService.manufacturers.some(item =>  userInput.includes(item.name))) {
      this.validManufacturer = false;
    }
    if (userInput === '') {
      this.validManufacturer = true;
    }
  }


  public setDescription() {
    if (this._manufacturer) {
      this.oeArticleCalculation.description = this._manufacturer.name;
    } else {
      this.oeArticleCalculation.description = this.translate.instant('OE_PART.ALL_MANUFACTURERS');
    }
    if (this._genArt) {
      this.oeArticleCalculation.description += ' - ' + this._genArt.description + '(' + this._genArt.id + ')';
    } else {
      this.oeArticleCalculation.description += ' - ' + this.translate.instant('OE_PART.ALL_ARTICLES');
    }
  }

  public setOperations() {
    this.oeArticleCalculation.computationMap.Flotte.operation =
      (document.getElementsByName('fOp1')[0] as HTMLInputElement).value + '_' +
      (document.getElementsByName('fOp2')[0] as HTMLInputElement).value;
    this.oeArticleCalculation.computationMap.Werkstatt.operation =
      (document.getElementsByName('wOp1')[0] as HTMLInputElement).value + '_' +
      (document.getElementsByName('wOp2')[0] as HTMLInputElement).value;

    if (this.oeArticleCalculation.computationMap.Flotte.operation === 'CONST_CHARGE') {
      this.oeArticleCalculation.computationMap.Flotte.operation = 'ADDITIONAL_CHARGE';
    }
    if (this.oeArticleCalculation.computationMap.Werkstatt.operation === 'CONST_CHARGE') {
      this.oeArticleCalculation.computationMap.Werkstatt.operation = 'ADDITIONAL_CHARGE';
    }
  }

  public checkOperations() {
    if (this.oeArticleCalculation.computationMap.Flotte.operation === 'ADDITIONAL_CHARGE') {
      this.oeArticleCalculation.computationMap.Flotte.operation = 'CONST_CHARGE';
    }
    if (this.oeArticleCalculation.computationMap.Werkstatt.operation === 'ADDITIONAL_CHARGE') {
      this.oeArticleCalculation.computationMap.Werkstatt.operation = 'CONST_CHARGE';
    }
  }


  public searchIndex(type: string): number {
    return this.oeArticleCalculation.conditions.findIndex(item => item.conditionField === type);
  }


  public saveOeArticleCalculation() {
    this.setDescription();
    this.setConditionsFromModel();
    this.setOperations();

    if (this.currentId) {
      this.calculationRestService.updateCalculation(this.oeArticleCalculation as ICalculation).subscribe(() => {
        this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'OE_PART');
      });
    } else {
      this.calculationRestService.createCalculation(this.oeArticleCalculation as ICalculation).subscribe(
        () => {
          this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'OE_PART');
          setTimeout(() => {
            const calculations = [];
            if (this.calculationService.calculations) {
              this.calculationService.calculations.value.forEach(elem => {
                calculations.push(elem);
              });
            }
            const mapped = calculations.map(calc => {
              return {id: calc.id, sortNo: calc.sortNo + 1};
            });
            this.calculationRestService.sortCalculation(this.fleetService.fleetId.value, mapped).subscribe();
          }, 250);
        });
    }
    this.router.navigate(['/fleet/' + this.fleetService.fleetId.value + '/oe_part']);
  }

  public conditionExists(type: string): boolean {
    return this.searchIndex(type) >= 0;
  }
}
