import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CalculationService} from '../../../../shared/services/calculation.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {IHourlyWageCalculation} from '../../../../shared/interfaces/hourly-wage.interfaces';
import {DefenitionRestService} from '../../../../shared/services/rest-services/defenition-rest.service';
import {FleetService} from '../../../../shared/services/fleet.service';
import {CalculationRestService} from '../../../../shared/services/rest-services/calculation-rest.service';
import {Subscription} from 'rxjs';
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-new-houely-wage-calculation',
  templateUrl: './new-hourly-wage-calculation.component.html'
})
export class NewHourlyWageCalculationComponent implements OnInit, OnDestroy {
  public currentId: number;
  public currentType: string;
  public hourlyWageCalculation: IHourlyWageCalculation;
  public hourlyWageUnits: string[];
  public hourlyWageTypes: string[];
  public routeSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fleetService: FleetService,
              private calculationService: CalculationService,
              private calculationRestService: CalculationRestService,
              public helperService: HelperService,
              private defenitionService: DefenitionRestService) {

    this.hourlyWageCalculation = {
      id: null,
      type: '',
      fleetId: null,
      amountDTOMap: {
        Flotte: {
          amount: null,
          unit: 'HOUR'
        },
        Werkstatt: {
          amount: null,
          unit: 'HOUR'
        }
      }
    };
  }

  ngOnInit() {
    this.setFleedId();
    this.getDefenitions();
    this.routeSubscription = this.route.parent.params.pipe(debounceTime(300)).subscribe((params) => {
      this.currentId = parseInt(params.hourlyWageCalcId);
      this.currentType = params.hourlyWageCalcId;
      if (isNaN(this.currentId) || (this.currentId === -1)) {
        this.hourlyWageTypes.forEach((element) => {
          element = element.toLowerCase();
          if (this.currentType.includes(element)) {
            this.hourlyWageCalculation.type = element.toUpperCase();
            this.hourlyWageCalculation.id = -1;
          }
        });
      }
      if (this.currentId > 0) {
        this.calculationService.setCurrentHourlyWageCalculationById(this.currentId);
        this.calculationService.currentHourlyWageCalculation.subscribe(data => {
          this.hourlyWageCalculation = data;
        });

      }
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }


  public setFleedId() {
    this.hourlyWageCalculation.fleetId = this.fleetService.fleetId.value;
  }

  public getDefenitions() {
    this.defenitionService.getDefenitionsByMethodeForType('hourly_wages_units').subscribe(data => this.hourlyWageUnits = data);
    this.defenitionService.getDefenitionsByMethodeForType('hourly_wages_types').subscribe(data => this.hourlyWageTypes = data);
  }

  public saveHourlyWageCalculation() {
    this.calculationRestService.updateHourlyWageCalculation(this.hourlyWageCalculation).subscribe(() => {
      this.router.navigate(['fleet/' + this.fleetService.fleetId.value + '/hourly_wage']);
      this.calculationService.getHourlyWagePartsByFleet(this.fleetService.fleetId.value);
    });
  }

}
