import {Component, OnDestroy, OnInit} from '@angular/core';
import {FleetService} from '../shared/services/fleet.service';
import {Subscription} from 'rxjs';
import {IFleet} from '../shared/interfaces/fleet.interfaces';
import {UserService} from '../shared/services/user.service';
import {AuthService} from '../auth/services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html'
})
export class TreeViewComponent implements OnInit, OnDestroy {

  public fleets: IFleet[];
  public submenus: string[];
  public currentFleet: IFleet;
  public currentSubmenu: string;
  public Subscriptions: Subscription [] = [];
  public showUserMenu: boolean;

  constructor(private authService: AuthService,
              private router: Router,
              public fleetService: FleetService,
              public userService: UserService) {
  }


  ngOnInit() {
    this.Subscriptions.push(this.fleetService.fleets.subscribe(
      data => this.fleets = data));

    this.Subscriptions.push(this.fleetService.currentFleet.subscribe(
      data => this.currentFleet = data));

    this.Subscriptions.push(this.fleetService.currentSubmenu.subscribe(
      data => this.currentSubmenu = data));

    this.Subscriptions.push(this.fleetService.getFleetSubmenu().subscribe(
      data => this.submenus = data));

  }

  public checkRouteBack(id: number): void {
    const url = this.router.url.split('/');
    const currentId = Number(url[2]);
    if (id !== currentId) {
      this.router.navigate(['/fleet/' + id + '/after_market']);
    }
  }

  ngOnDestroy() {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });

  }


}
