<ng-container *ngIf="fixedPriceCalculations.length >0">
  <div class="custom-list">
    <div class="list-item list-header">
      <div class="row">
        <div class="col-3">
          {{ 'GENERAL.DESCRIPTION' | translate }}
        </div>
        <div class="col-3">
          {{ 'GENERAL.FLEET' | translate }}
        </div>
        <div class="col-3">
          {{ 'GENERAL.GARAGE' | translate }}
        </div>
        <div class="col-3 text-right">
          {{ 'GENERAL.OPTIONS' | translate }}
        </div>
      </div>
    </div>
    <div *ngFor="let calculation of fixedPriceCalculations" class="list-item" id="{{calculation.id}}"
         [ngClass]="{'inactive': !calculation.active, 'affirm': fleetService.highlightedCalculationId.value === calculation.id}">
      <div (click)="calculationService.setCurrentCalculationById(calculation.id)" class="row">
        <div class="col-3 d-flex align-items-center">
          {{ calculation.description }}
        </div>
        <div class="col-3 d-flex align-items-center">
          {{ calculation.computationMap.Flotte.value }}
          <span> {{ (calculation.computationMap.Flotte.operation.includes('PERCENTAL') ? '%' : (helperService.getCurrencySmbol() | currencySymbol)) }}</span>
        </div>
        <div class="col-3 d-flex align-items-center">
          {{ calculation.computationMap.Werkstatt.value }}
          <span> {{ (calculation.computationMap.Werkstatt.operation.includes('PERCENTAL') ? '%' : (helperService.getCurrencySmbol() | currencySymbol)) }}</span>
        </div>
        <div class="col-3 text-right">
          <i class="n4icon-edit-pen-outline m-2"
             (click)="calculationService.setCurrentCalculationById(calculation.id)"
             [routerLink]="['/fleet/' + fleetService.fleetId.value + '/fixed_price/' + calculation.id + '/edit_fixed_price_calculation']">
          </i>
          <i class="n4icon-trash-outline m-2"
             (click)="calculationService.setCurrentCalculationById(calculation.id); open(content)"></i>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="!fixedPriceCalculations || fixedPriceCalculations.length== 0">
  <div class="element-box min-h-2 w-100 m-0 mt-3">
    <span class="empty-element">{{ 'GENERAL.NO_RESULTS' | translate }}</span>
  </div>
</ng-container>

<div class="button-group">
  <button type="submit" class="btn btn-outline-secondary float-right ml-2"
          (click)="helperService.locationBackfromMenu(true)">
    {{ 'BTN.BACK' | translate }}
  </button>
  <button type="submit" class="btn btn-primary float-right ml-2"
          [routerLink]="['/fleet/' + fleetService.fleetId.value + '/fixed_price/new_fixed_price_calculation/']">
    {{ 'BTN.NEW_FIXED_PRICE' | translate }}
  </button>
  <button type="submit" class="btn btn-primary float-right ml-2"
          (click)="csvDownload()">
    {{ 'BTN.CSV_DOWNLOAD' | translate }}
  </button>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'FIXED_PRICE.DELETE_CALCULATION' | translate }}</h4>
    <button type="button" class="a" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">{{ 'BTN.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-danger"
            (click)="modal.close('Save click'); deleteCalculation(calculationService.calcIdId.value)">{{ 'BTN.DELETE_CALCULATION' | translate }}
    </button>
  </div>
</ng-template>
