import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {ToastService} from '../toast.service';
import {LoadingService} from '../loading.service';
import {TranslateService} from '@ngx-translate/core';
import {finalize, last, tap} from 'rxjs/operators';
import {FleetService} from '../fleet.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private location: Location,
              private toastService: ToastService,
              public fleetService: FleetService,
              private loadingService: LoadingService,
              private translate: TranslateService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const message = this.translate.instant('ERROR.GENERIC_SERVER_ERROR');
    const messageSuccess = this.translate.instant('MESSAGE.SUCCESS');
    const messageUnauthorized = this.translate.instant('ERROR.UNAUTHORIZED');
    let authReq = req.clone({headers: req.headers.set('withCredentials', 'true')});
    authReq = authReq.clone({headers: req.headers.set('X-Requested-With', 'XMLHttpRequest')});
    this.loadingService.loading.next(true);

    return next.handle(authReq)
      .pipe(last(), finalize(() => {
          setTimeout(() => {
            this.loadingService.loading.next(false);
          }, 250)
        }), tap(event => {
          if (authReq.method !== 'GET') {
            this.toastService.show(messageSuccess, {classname: 'bg-success text-light', delay: 5000});
          }
        }, error => {
          if (error.status === 401 ) {
            if (this.location.path() !== "/login") {
              this.toastService.show(messageUnauthorized, {classname: 'bg-danger text-light', delay: 5000});
              this.fleetService.reset();
              this.router.navigate(['/login']);
            }
          } else {
            this.toastService.show(message, {classname: 'bg-danger text-light', delay: 5000});
          }

          return throwError(error);
        })
      );
  }
}
