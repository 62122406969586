import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fleet-menu',
  templateUrl: './fleet-menu.component.html'
})
export class FleetMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
