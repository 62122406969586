<div class="mb-2" *ngIf="currentSubmenu && currentSubmenu !=='HOURLY_WAGE' && !helperService.checkEditOrNewPage()">
  <ngb-accordion #acc1="ngbAccordion">
    <ngb-panel>
      <ng-template ngbPanelHeader>
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><span
            class="mr-auto"> {{'CALCULATION.CHECK' | translate}}</span></button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <form #appForm="ngForm">
          <div class="form-group row" *ngIf="currentSubmenu === 'AFTER_MARKET'">
            <label for="dlnr" class="col-2 col-form-label">{{'CALCULATION.BRAND' | translate}}</label>
            <div class="col-10">
              <input id="dlnr" type="text" name="dlnr"
                     class="form-control" #name="ngModel"
                     [ngbTypeahead]="dataService.searchDlnr"
                     [container]="'body'"
                     (selectItem)="setDlnr($event)"
                     [resultFormatter]="helperService.formatterName"
                     [inputFormatter]="helperService.formatterName"
                     [editable]='false'
                     [(ngModel)]="_dlnr"
                     required/>
              <span class="show-required-star"></span>
            </div>
          </div>
          <div class="form-group row" *ngIf="currentSubmenu === 'OE_PART'">
            <label for="manufacturer" class="col-2 col-form-label">{{'CALCULATION.BRAND' | translate}}</label>
            <div class="col-10">
              <input id="manufacturer" type="text" name="manufacturer"
                     class="form-control" #name="ngModel"
                     [ngbTypeahead]="dataService.searchManufacturer"
                     [container]="'body'"
                     (selectItem)="setManufacturer($event)"
                     [resultFormatter]="helperService.formatterName"
                     [inputFormatter]="helperService.formatterName"
                     [editable]='false'
                     [(ngModel)]="_manufacturer"
                     required/>
              <span class="show-required-star"></span>
            </div>
          </div>
          <div class="form-group row">
            <label for="genArt" class="col-2 col-form-label">{{'FIXED_PRICE.GEN_ART' | translate}}</label>
            <div class="col-10">
              <input id="genArt" type="text" name="genArt"
                     class="form-control" #name="ngModel"
                     [ngbTypeahead]="dataService.searchGenArt"
                     [container]="'body'"
                     (selectItem)="setGenArt($event)"
                     [resultFormatter]="helperService.formatterDescriptionWithId"
                     [inputFormatter]="helperService.formatterDescription"
                     [editable]='false'
                     [(ngModel)]="_genArt"
                     required/>
              <span class="show-required-star"></span>
            </div>
          </div>
          <div class="form-group row">
            <label for="price" class="col-2 col-form-label">{{'CALCULATION.PRICE' | translate}}</label>
            <div class="col-10">
              <input id="price" type="number" name="price" class="form-control" #name="ngModel"
                     [(ngModel)]="search.price"
                     pattern="[0-9]+([\.,][0-9]+)?" step="0.01">
              <span class="show-required-star"></span>
            </div>
          </div>
        </form>
        <div class="button-group">
          <button type="submit" class="btn btn-primary float-left" (click)="searchForCalculation()"
                  [disabled]="appForm.invalid">
            {{'BTN.CHECK' | translate }}</button>
        </div>
        <div *ngIf="!calculationFound && (calculation && calculation.length <= 0)" class="alert alert-warning m-3"
             role="alert">
          {{'CALCULATION.NO_CALCULATION' | translate}}
        </div>
        <hr class="mt-5" *ngIf="calculationFound && calculation">
        <div *ngIf="calculationFound && calculation" class="grid-form small-gap mt-1">
          <div>{{'CALCULATION.PRICE_FLEET' | translate}}:</div>
          <div><b>{{calculation.amountDTOMap.Flotte.amount}}  {{(helperService.getCurrencySmbol() | currencySymbol)}}
            / {{'CALCULATION.' + calculation.amountDTOMap.Flotte.unit | translate}}</b></div>
          <div> {{'CALCULATION.PRICE_GARAGE' | translate}}:</div>
          <div>
            <b>{{calculation.amountDTOMap.Werkstatt.amount}}  {{(helperService.getCurrencySmbol() | currencySymbol)}}
              / {{'CALCULATION.' + calculation.amountDTOMap.Werkstatt.unit | translate}}</b></div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
