<div class="sidebar overflow-auto">
  <div class="nav-image">
    <img src="assets/images/fleet-logo.png" [routerLink]="['/fleet']">
  </div>
  <div class="nav-item">
    <button type="button" class="collapsible with-icon"
            [ngClass]="{'active': showUserMenu}"
            (click)="showUserMenu = !showUserMenu">
      <i class="n4icon-user-outline"></i>{{userService?.userData?.userName}}
      <i [ngClass]="showUserMenu ? 'n4icon-arrow-up-outline' : 'n4icon-arrow-down-outline'"
         class="collapsible-arrow"></i>
    </button>
    <div class="content">
      <ul>
        <li (click)="logout()">{{'HEADER.LOGOUT' | translate}}</li>
      </ul>
    </div>
  </div>
  <div *ngFor="let fleet of fleets" class="nav-item" (click)="fleetService.setCurrentFleetById(fleet.id);">
    <button type="button" class="collapsible with-icon"
            (click)="checkRouteBack(fleet.id); fleet.isActive = !fleet.isActive;"
            [ngClass]="{'active': fleet.isActive}">
      <i class="n4icon-car-suv-outline"></i>
      {{fleet.fleetname}}
      <i [ngClass]="fleet.isActive ? 'n4icon-arrow-up-outline' : 'n4icon-arrow-down-outline'"
         class="collapsible-arrow"></i>
    </button>
    <div class="content">
      <ul>
        <li *ngFor="let item of submenus" routerLinkActive="active"
            (click)="fleetService.setCurrentSubmenu(item)"
            [routerLink]="['/fleet/' + fleet.id + '/' + (item | lowercase)]">{{'FLEET.' + item | translate}}</li>
      </ul>
    </div>
  </div>
</div>
