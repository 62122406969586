import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFleet } from '../../interfaces/fleet.interfaces';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FleetRestService {

  constructor(private http: HttpClient) {
  }

  public getFleets(): Observable<IFleet[]> {
    return this.http.get<IFleet[]>(
      `${environment.internal_apiUrl}/fleet/`
    );
  }

  public getFleetById(id: number): Observable<IFleet> {
    return this.http.get<IFleet>(
      `${environment.internal_apiUrl}/fleet/${id}`
    );
  }

  public createFleet(fleet: IFleet): Observable<IFleet> {
    return this.http.post<IFleet>(
      `${environment.internal_apiUrl}/fleet/`, fleet
    );
  }

  public updateFleet(fleet: IFleet): Observable<IFleet> {
    return this.http.put<IFleet>(
      `${environment.internal_apiUrl}/fleet/${fleet.id}`, fleet
    );
  }

  public deleteFleet(fleetId: number): Observable<IFleet> {
    return this.http.delete<IFleet>(
      `${environment.internal_apiUrl}/fleet/${fleetId}`
    );
  }

}
