<div class="fleet-container">
  <div class="custom-list">
    <div class="list-item list-header">
      <div class="row">
        <div class="col">
          {{'GENERAL.NAME' | translate}}
        </div>
        <div class="col-1 text-right">
          {{'GENERAL.OPTIONS' | translate}}
        </div>
      </div>
    </div>
    <div *ngFor="let fleet of fleets; let i = index"
         class="list-item">
      <div class="row">
        <div class="d-flex justify-content-center">
          <i class="n4icon-arrow-down-outline  m-2" (click)="fleet.showMenu = !fleet.showMenu">
          </i>
        </div>
        <div class="col">
          {{fleet.fleetname}}
        </div>
        <div class="col-1 text-right">
          <i class="n4icon-edit-pen-outline  m-2"
             [routerLink]="['/fleet/' + fleet.id + '/edit_fleet']">
          </i>
        </div>
        <div class="col-12" *ngIf="fleet.showMenu">
          <ul class="state-list ml-5 mb-0">
            <li  *ngFor="let item of submenus"  class="state-list-item cursor-pointer"
                [routerLink]="['/fleet/' + fleet.id + '/' + (item | lowercase)]">
              <span class="dot dot-default"></span>
              <span>{{'FLEET.' +item | translate}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
