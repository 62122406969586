import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DefenitionRestService {

  constructor(private http: HttpClient) {
  }


  public getDefenitionsByMethodeForType(methode: string, type?: string) {
    if (type) {
      return this.http.get<string[]>(
        `${environment.internal_apiUrl}/definitions/${methode}/forType/${type}`
      );
    } else {
      return this.http.get<string[]>(
        `${environment.internal_apiUrl}/definitions/${methode}`
      );
    }
  }
}
