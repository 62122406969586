import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './shared/services/user.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public browserIsIe: boolean;
  public UserSubscription: Subscription;

  constructor(private translate: TranslateService,
              private userService: UserService) {

    translate.setDefaultLang('en');
  }

  title = 'N4Fleet';

  ngOnInit(): void {
    this.UserSubscription= this.userService.userData$.subscribe(response => {
      if (response) {
        this.translate.use(response.language);
      }
    });
    this.browserIsIe = this.checkBrowserIsIE();
  }

  public checkBrowserIsIE() {
    const ua = window.navigator.userAgent;
    return (ua.indexOf('Trident/') > 0) || (ua.indexOf('MSIE ') > 0);
  }

}
