import { Component, OnDestroy, OnInit } from '@angular/core';
import { FleetService } from '../../services/fleet.service';
import { Subscription } from 'rxjs';
import { IFleet } from '../../interfaces/fleet.interfaces';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public Subscriptions: Subscription [] = [];
  public currentFleet: IFleet;
  public currentSubmenu: string;

  constructor(public fleetService: FleetService,
              public helperService: HelperService,
              private router: Router) {
  }

  ngOnInit() {
    this.Subscriptions.push(this.fleetService.currentFleet.subscribe(
      data => this.currentFleet = data));

    this.Subscriptions.push(this.fleetService.currentSubmenu.subscribe(
      data => {
        this.currentSubmenu = data
      })
    );
  }

  public checkUrlMenu(submenu: string): boolean {
    return this.router.url.includes(submenu);
  }

  ngOnDestroy() {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

}
