import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import 'lodash';
import {IMCUpdate} from '../../../shared/interfaces/material-code';
import {MaterialCodesRestService} from '../../../shared/services/rest-services/material-codes-rest.service';
import {Subscription} from 'rxjs';
import {FleetService} from '../../../shared/services/fleet.service';
import {Router} from '@angular/router';
import {DataService} from "../../../shared/services/data.service";
import {element} from "protractor";

declare var _: any;

@Component({
  selector: 'app-tree-item',
  templateUrl: './tree-item.component.html'
})
export class TreeItemComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() mcList: any;
  @Input() level: number;
  @Input() show: boolean;
  public closeResult: string;
  public selectedAll: any[] = [];
  public allArticles: any[] = [];
  public selectedWithoutMC: any[] = [];
  public materialCode: string;
  public mc: IMCUpdate;
  public Subscriptions: Subscription [] = [];
  public fleetId: number;
  public deleteAtIds: number[] = [];

  constructor(private modalService: NgbModal,
              private fleetService: FleetService,
              private router: Router,
              private materialCodesRestService: MaterialCodesRestService,
              private dataService: DataService) {
    this.mc = {} as IMCUpdate;
  }

  ngOnInit(): void {
    this.Subscriptions.push(this.fleetService.fleetId.subscribe(id => this.fleetId = id));
  }

  ngOnDestroy() {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public addToMaterialCode(type: string) {
    switch (type) {
      case 'all': {
        this.mc.genArtIds = this.selectedAll;
        break;
      }
      case 'without-mc': {
        let genArtIds = [];
        this.selectedWithoutMC.forEach((element) => {
          genArtIds.push(element.id)
        })
        this.mc.genArtIds = genArtIds;
        break;
      }
    }


    this.mc.fleetId = this.fleetId;
    this.mc.materialCode = this.materialCode;
    this.materialCodesRestService.createOrUpdateMaterialCodes(this.mc).subscribe(() => {
      const currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
        });
      }
    );

  }

  public setGenArts(item: any) {
    const selectedItem = item;
    this.selectedAll = selectedItem.genericArticleIds;
    selectedItem.genericArticleIds.forEach((element, index) => {
      this.dataService.genArts.forEach((genArt) => {
        if (element === genArt.id) {
          this.allArticles.push(genArt)
          this.selectedWithoutMC.push(genArt)
        }
      })
      this.mcList.forEach(array1 => {
        array1.forEach(array2 => {
          if (array2.genArtId === element) {
            this.deleteAtIds.push(element)
          }
        })
      })
    })
    for (let i = 0; i < this.deleteAtIds.length; i++) {
      this.allArticles.forEach((article) => {
        if (article.id === this.deleteAtIds[i]) {
          _.pull(this.selectedWithoutMC, article);
        }
      })
    }
    _.uniq(this.selectedWithoutMC);
    _.uniq(this.allArticles);
  }

  public open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: 'new-material-code-modal'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }

}
