<div class="custom-list">
  <div class="list-item list-header">
    <div class="row">
      <div class="col-3">
        {{ 'GENERAL.DESCRIPTION' | translate }}
      </div>
      <div class="col-3">
        {{ 'GENERAL.FLEET' | translate }}
      </div>
      <div class="col-3">
        {{ 'GENERAL.GARAGE' | translate }}
      </div>
      <div class="col-3 text-right">
        {{ 'GENERAL.OPTIONS' | translate }}
      </div>
    </div>
  </div>
  <div *ngFor="let calculation of hourlyWagePartsCalculation; let i = index"
       class="list-item">
    <div (click)="calculationService.setCurrentHourlyWageCalculationById(calculation.id)" class="row">
      <div class="col-3 align-self-center">
        {{ 'HOURLY_WAGE.PARTS.' + calculation.type | translate }}
      </div>
      <div class="col-6">
        <div *ngIf="(calculation.amountDTOMap | json) != '{}'">
          <div class="w-100 d-flex">
            <div *ngFor="let item of calculation.amountDTOMap | keyvalue" class="w-50">
            <span *ngFor="let item of item.value | keyvalue">
              <span *ngIf="item.key==='amount'">
                &nbsp;{{ item.value }}&nbsp; {{ (helperService.getCurrencySmbol() | currencySymbol) }}
              </span>
              <span *ngIf="item.key==='unit'">
                /{{ 'HOURLY_WAGE.UNITS.' + item.value | translate }}
              </span>
            </span>
            </div>
          </div>
        </div>
        <div *ngIf="(calculation.amountDTOMap | json) == '{}'" class="center">
          {{ 'HOURLY_WAGE.WAGE_INFO_NOT_SET' | translate }}
        </div>
      </div>
      <div class="col-3 text-right">
        <i class="n4icon-edit-pen-outline  m-2"
           (click)="calculationService.setCurrentHourlyWageCalculationById(calculation.id)"
           [routerLink]="['/fleet/' + fleetService.fleetId.value + '/hourly_wage/' + calculation.id +'_' + calculation.type.toLowerCase() + '/edit_hourly_wage_calculation']">
        </i>
      </div>
    </div>
  </div>
</div>
<div class="button-group">
  <button type="submit" class="btn btn-outline-secondary float-right ml-2"
          (click)="helperService.locationBackfromMenu(true)">{{ 'BTN.BACK' | translate }}
  </button>
  <button type="submit" class="btn btn-primary float-right ml-2"
          (click)="csvDownload()">
    {{ 'BTN.CSV_DOWNLOAD' | translate }}
  </button>
</div>



