import {Injectable} from '@angular/core';
import {DataRestService} from './rest-services/data-rest.service';
import {IDlnr, IGenArt, IManufacturer} from '../interfaces/data.interface';
import {catchError, debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {BehaviorSubject, merge, Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public genArts: IGenArt[];
  public dlnrs: IDlnr[];
  public manufacturers: IManufacturer[];
  public tree$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private dataRestService: DataRestService,
              public translate: TranslateService) {
  }

  public getGenArts() {
    this.dataRestService.getGenArts().subscribe(data => this.genArts = data);
  }

  public getManufacturers() {
    this.dataRestService.getManufacturer().subscribe(data => this.manufacturers = data);
  }

  public getDlnrs() {
    this.dataRestService.getDlnr().subscribe(data => this.dlnrs = data);
  }

  public getTree() {
    this.dataRestService.getTree().subscribe(data => this.tree$.next(data));
  }

  public getNameforGenArt(id: number) {
    if (id && this.genArts) {
      const filtered = this.genArts.find(elm => elm.id === id);
      if (filtered) {
        return filtered.description;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }


  public searchGenArt = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$).pipe(
      map(term => {
          const filtered = this.genArts.filter(v => (v.description.toLowerCase().indexOf(term.toLowerCase()) > -1) || v.id.toString().indexOf(term) > -1);
          if (term !== '') {
            if (filtered && filtered.length >= 1) {
              return filtered
            } else {
              return [{
                id: '0',
                description: this.translate.instant('GENERAL.NO_RESULTS')
              }];
            }
          }
        },
        catchError(() => {
          return of([]);
        }))
    )
  }

  public searchDlnr = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$).pipe(
      map(term => {
          const filtered = this.dlnrs.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1);
          if (term !== '') {
            if (filtered && filtered.length >= 1) {
              return filtered
            } else {
              return [{
                id: '0',
                name: this.translate.instant('GENERAL.NO_RESULTS')
              }];
            }
          }
        },
        catchError(() => {
          return of([]);
        }))
    )
  }

  public searchManufacturer = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$).pipe(
      map(term => {
          const filtered = this.manufacturers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1);
          if (term !== '') {
            if (filtered && filtered.length >= 1) {
              return filtered
            } else {
              return [{
                id: '0',
                name: this.translate.instant('GENERAL.NO_RESULTS')
              }];
            }
          }
        },
        catchError(() => {
          return of([]);
        }))
    )
  }

}
