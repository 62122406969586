<form name="form" class="mt-3" (ngSubmit)="oeArticleForm.form.valid && saveOeArticleCalculation()" #oeArticleForm="ngForm">
  <div class="row no-gutters data-box" *ngIf="oeArticleCalculation">
    <div class="col-12 mb-3">
      <div class="row">
        <div class="col-12">
          <input type="checkbox" class="toggle toggle-primary" id="active"
                 [(ngModel)]="oeArticleCalculation.active" name="active">
          <label for="active">{{'OE_PART.ACTIVE' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="manufacturer" class="col-2 col-form-label">{{'OE_PART.ALL_MANUFACTURERS' | translate}}</label>
        <div class="col-10">
          <input id="manufacturer" type="text" name="dlnr"
                 placeholder="{{'OE_PART.ALL_MANUFACTURERS' | translate}}"
                 class="form-control" #name="ngModel"
                 [ngbTypeahead]="dataService.searchManufacturer"
                 [container]="'body'"
                 (selectItem)="setManufacturer($event)"
                 (change)="checkValidEntryManufacturer($event)"
                 [ngClass]="{'is-invalid': !validManufacturer }"
                 [resultFormatter]="helperService.formatterName"
                 [inputFormatter]="helperService.formatterName"
                 [editable]='false'
                 [(ngModel)]="_manufacturer"/>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="genArt" class="col-2 col-form-label">{{'OE_PART.GEN_ART' | translate}}</label>
        <div class="col-10">
          <input id="genArt" type="text" name="genArt"
                 class="form-control" #name="ngModel"
                 placeholder="{{'OE_PART.ALL_ARTICLES' | translate}}"
                 [ngbTypeahead]="dataService.searchGenArt"
                 [container]="'body'"
                 (change)="checkValidEntryGenArt($event)"
                 [ngClass]="{'is-invalid': !validGenArt }"
                 (selectItem)="setGenArt($event)"
                 [resultFormatter]="helperService.formatterDescriptionWithId"
                 [inputFormatter]="helperService.formatterDescriptionWithId"
                 [editable]='false'
                 [(ngModel)]="_genArt"/>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="unit" class="col-2 col-form-label">{{'OE_PART.UNIT' | translate}}</label>
        <div class="col-10">
          <select required type="text" name="unit" class="form-control" #name="ngModel" id="unit"
                  [(ngModel)]="oeArticleCalculation.unit">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option *ngFor="let unit of calculationUnits"
                    [value]="unit">{{'OE_PART.UNITS.' + unit | translate | slice:3}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="quantity" class="col-2 col-form-label">{{'OE_PART.QUANTITY' | translate}}</label>
        <div class="col-10">
          <input id="quantity" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="oeArticleCalculation.quantity"
                 step="1" min="0" name="quantity" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="quantity" class="col-2 col-form-label">{{'MATERIAL_CODES.MC' | translate}}</label>
        <div class="col-10">
          <input id="material-code" type="text" class="form-control" #name="ngModel"
                 [(ngModel)]="oeArticleCalculation.materialCode"
                 [disabled]="true"
                 name="material-code" >
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-primary font-weight-bold">
          {{'OE_PART.FLEET' |translate}}:
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fOp2" class="col-2 col-form-label">{{'OE_PART.OPERATION_TYPE' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="oeArticleCalculation.computationMap.Flotte.operation.split('_')[1]" name="fOp2"
                  id="fOp2">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option value="CHARGE">{{'OE_PART.OPERATION_TYPES.CHARGE' | translate}}</option>
            <option value="DISCOUNT">{{'OE_PART.OPERATION_TYPES.DISCOUNT' | translate}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fOp1" class="col-2 col-form-label">{{'OE_PART.OPERATION' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="oeArticleCalculation.computationMap.Flotte.operation.split('_')[0]" name="fOp1"
                  id="fOp1">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option value="PERCENTAL">%</option>
            <option value="CONST">{{helperService.getCurrencySmbol() | currencySymbol}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fvalue" class="col-2 col-form-label">{{'OE_PART.AMOUNT' | translate}}</label>
        <div class="col-10">
          <input id="fvalue" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="oeArticleCalculation.computationMap.Flotte.value"
                 pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="fvalue" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-primary font-weight-bold">
          {{'OE_PART.GARAGE' |translate}}:
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="wOp2" class="col-2 col-form-label">{{'OE_PART.OPERATION_TYPE' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="oeArticleCalculation.computationMap.Werkstatt.operation.split('_')[1]" name="wOp2"
                  id="wOp2">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option value="CHARGE">{{'OE_PART.OPERATION_TYPES.CHARGE' | translate}}</option>
            <option value="DISCOUNT">{{'OE_PART.OPERATION_TYPES.DISCOUNT' | translate}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="wOp1" class="col-2 col-form-label">{{'OE_PART.OPERATION' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="oeArticleCalculation.computationMap.Werkstatt.operation.split('_')[0]" name="wOp1"
                  id="wOp1">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option value="PERCENTAL">%</option>
            <option value="CONST">{{helperService.getCurrencySmbol() | currencySymbol}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="gvalue" class="col-2 col-form-label">{{'OE_PART.AMOUNT' | translate}}</label>
        <div class="col-10">
          <input id="gvalue" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="oeArticleCalculation.computationMap.Werkstatt.value"
                 pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="gvalue" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12  button-group">
          <button type="submit" [disabled]="!(oeArticleForm.form.valid && validManufacturer && validGenArt)"
                  class="btn btn-primary float-left">{{'BTN.SAVE' | translate }}</button>
          <button type="button" (click)="helperService.locationBackfromMenu()"
                  class="btn btn-outline-secondary float-right">{{'BTN.CANCEL' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</form>
