import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MaterialCodesRestService} from '../../../shared/services/rest-services/material-codes-rest.service';
import {FleetService} from '../../../shared/services/fleet.service';
import {DataService} from '../../../shared/services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IMCUpdate} from '../../../shared/interfaces/material-code';
import {HelperService} from '../../../shared/services/helper.service';
import 'lodash';
import {Location} from "@angular/common";
import {MaterialCodesService} from "../../../shared/services/material-codes.service";

declare var _: any;


@Component({
  selector: 'app-new-material-codes',
  templateUrl: './new-material-codes.component.html'
})
export class NewMaterialCodesComponent implements OnInit, OnDestroy {
  @ViewChild('duplicateDetected') duplicateDetected: TemplateRef<any>;

  public Subscriptions: Subscription [] = [];
  public currentId: string;
  public fleetId: number;
  public selectedIndex: number;
  public materialCodes: any [] = [];
  public allMaterialCodes: any [] = [];
  public closeResult: string;
  public mc: IMCUpdate;
  public newGenArtId: any;
  public duplicateArticle: string;
  public duplicateMC: string;

  constructor(private route: ActivatedRoute,
              private location: Location,
              private materialCodesRestService: MaterialCodesRestService,
              public helperService: HelperService,
              private modalService: NgbModal,
              private fleetService: FleetService,
              public dataService: DataService,
              public materialCodesService: MaterialCodesService
  ) {
    this.mc = {} as IMCUpdate;
  }

  ngOnInit(): void {
    this.Subscriptions.push(this.fleetService.fleetId.subscribe(id => this.fleetId = id));
    this.Subscriptions.push(this.route.parent.params.subscribe((params) => {
      this.currentId = params.mcId;
      if (this.currentId) {
       this.getMaterialCodes()
      }
    }));
    this.Subscriptions.push(this.materialCodesService.materialCodes.subscribe(
      (data) => {
        if (data) {
          const grouped = _.groupBy(data, mc => mc.materialCode);
          this.allMaterialCodes = _.toArray(grouped);
        } else {
          this.allMaterialCodes = [];
        }
      }));
  }

  ngOnDestroy() {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
    this.fleetService.currentSubmenu.next(null);
  }

  public getMaterialCodes() {
    this.materialCodesRestService.getMaterialCodeForIdByFleet(this.currentId, this.fleetId).subscribe(mc => this.materialCodes = mc);
  }
  public deleteGenArt() {
    this.mc.replace = true;
    const id = this.materialCodes[this.selectedIndex].genArtId;
    this.mc.genArtIds = this.materialCodes.filter(x => x.genArtId !== id).map(y => y.genArtId);
    this.materialCodes.splice(this.selectedIndex, 1)
    this.mc.replace = true;
  }

  public createOrUpdateMaterialCode(deletion?: boolean) {
    this.mc.fleetId = this.fleetId;
    this.mc.materialCode = this.currentId;
    this.mc.genArtIds = this.materialCodes.map(x => x.genArtId);
    this.materialCodesRestService.createOrUpdateMaterialCodes(this.mc).subscribe(() => {
      this.location.replaceState('/fleet/' + this.fleetId + '/material_codes/' + this.currentId + '/edit_material_codes');
      if (this.mc.replace) {
        _.pullAt(this.materialCodes, this.selectedIndex);
      }
      this.mc.replace = false;
      this.getMaterialCodes();
    });
  }

  public open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }

  public setNewGenArt(event) {
    if (event && event.item && event.item.id) {
      this.newGenArtId = event.item.id;
      this.allMaterialCodes.forEach((materialCode) => {
        materialCode.forEach((position) => {
          if (position.genArtId === this.newGenArtId) {
            this.duplicateArticle = event.item.description + ' (' + this.newGenArtId + ')';
            this.duplicateMC = position.materialCode;
            this.open(this.duplicateDetected);
          }
        })
      })
    } else {
      this.newGenArtId = null;
    }
  }

  public addNewGenArtToMC() {
    this.materialCodes.push({
      fleetId: this.fleetId,
      genArtId: this.newGenArtId.id,
      materialCode: this.currentId
    })
  }

}
