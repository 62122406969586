<form class="mt-3" name="form"  #mcForm="ngForm">
<div class="mt-3 row no-gutters">
  <div class="col-12">
    <div class="data-box h-100">

      <div class="d-flex align-items-center mb-5">
        <div class="form-group row w-100">
          <label for="mc" class="col-2 pl-0 col-form-label">{{'MATERIAL_CODES.MC' | translate}}</label>
          <div class="col-10">
            <input type="text" id="mc" class="form-control" [(ngModel)]="currentId" name="mc"
                   required/>
            <span class="show-required-star"></span>
          </div>
        </div>
      </div>


      <div class="data-header">
        <div class="d-flex align-items-center">
          {{'MATERIAL_CODES.GEN_ART' | translate}} -
          <span (click)="open(content2)" class="text-primary cursor-pointer">
                {{'BTN.ADD' | translate}}
            </span>
          <i (click)="open(content2)" class="ml-1 mt-1 n4icon-plus-add-circle-outline cursor-pointer"></i>
        </div>
      </div>
      <ng-container *ngIf="materialCodes.length >0">
        <div class="custom-list">
          <div class="list-item list-header">
            <div class="row">
              <div class="col">
                {{'MATERIAL_CODES.GEN_ART' | translate}} ({{'MATERIAL_CODES.GEN_ART_ID' | translate}})
              </div>
              <div class="col-3 text-right">
                {{'GENERAL.OPTIONS' | translate}}
              </div>
            </div>
          </div>
          <div *ngFor="let mc of materialCodes; let idx = index" class="list-item">
            <div class="row">
              <div class="col">
                {{dataService.getNameforGenArt(mc.genArtId) + ' (' + mc.genArtId + ')'}}
              </div>
              <div class="col-3 text-right">
                <i (click)="selectedIndex = idx; open(content)" class="n4icon-trash-outline m-2">
                </i>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!materialCodes || materialCodes.length== 0">
        <div class="element-box min-h-2 w-100 m-0 mt-3">
          <span class="empty-element">{{'GENERAL.NO_RESULTS' | translate}}</span>
        </div>
      </ng-container>


      <ng-container>
        <div class="row">
          <div class="col-12  button-group">
            <button type="button" (click)="helperService.locationBackfromMenu()"
                    class="btn btn-outline-secondary float-left">{{'BTN.BACK' | translate }}
            </button>
            <button type="submit" (click)="createOrUpdateMaterialCode()"
                    [disabled]="!currentId"
                    class="btn btn-primary float-right">{{'BTN.SAVE' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'MATERIAL_CODES.DELETE_ARTICLE' | translate}}</h4>
    <button type="button" class="close no-outline" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">{{'BTN.CANCEL' | translate}}</button>
    <button type="button" class="btn btn-danger"
            (click)="modal.close('Save click'); deleteGenArt()">{{'BTN.DELETE_ARTIKLE' | translate}}</button>
  </div>
</ng-template>


<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title2">{{'MATERIAL_CODES.CREATE_GEN_ART' | translate}}</h4>
    <button type="button" class="close no-outline" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <br>
    <div class="form-group row">
      <label for="genArt" class="col-2 col-form-label">{{'FIXED_PRICE.GEN_ART' | translate}}</label>
      <div class="col-10">
        <input id="genArt" type="text" name="genArt"
               class="form-control"
               placeholder="{{'FIXED_PRICE.ALL_ARTICLES' | translate}}"
               [ngbTypeahead]="dataService.searchGenArt"
               [container]="'body'"
               (selectItem)="setNewGenArt($event)"
               [(ngModel)]="newGenArtId"
               [resultFormatter]="helperService.formatterDescriptionWithId"
               [inputFormatter]="helperService.formatterDescriptionWithId"
               [editable]='false' required/>
        <span class="show-required-star"></span>
      </div>
    </div>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">{{'BTN.CANCEL' | translate}}</button>
    <button type="button" class="btn btn-outline-primary"
            [disabled]="!newGenArtId"
            (click)="addNewGenArtToMC(); setNewGenArt(null)">{{'BTN.ADD' | translate}}</button>
    <button type="button" class="btn btn-outline-primary"
            [disabled]="!newGenArtId"
            (click)="addNewGenArtToMC(); modal.close('Save click')">{{'BTN.ADD_CLOSE' | translate}}</button>
  </div>
</ng-template>

  <ng-template #duplicateDetected let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title3">{{'MATERIAL_CODES.DUPLICATE_DETECTED' | translate}}</h4>
      <button type="button" class="close no-outline" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{'MATERIAL_CODES.DUPLICATE_TEXT' | translate}}
      <br><br>
      {{'AFTERMARKET.GEN_ART' | translate}}: <b>{{duplicateArticle}}</b>
      <br>
      {{'MATERIAL_CODES.MC' | translate}}:  <b>{{duplicateMC}}</b>
      <br><br>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary"
              (click)="modal.close()">{{'BTN.OK' | translate}}</button>
    </div>
  </ng-template>
</form>
