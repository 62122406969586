<form name="form" class="mt-3" (ngSubmit)="fixedPriceForm.form.valid && saveFixedPriceCalculation()" #fixedPriceForm="ngForm"
      id="form">
  <div class="row no-gutters data-box" *ngIf="fixedPriceCalculation">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <input type="checkbox" class="toggle toggle-primary" id="active"
                 [(ngModel)]="fixedPriceCalculation.active" name="active">
          <label for="active">{{'FIXED_PRICE.ACTIVE' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div class="row">
        <div class="col-12">
          <input type="checkbox" class="toggle toggle-primary" id="showService"
                 [(ngModel)]="showService" (change)="serviceChanged()" name="showService">
          <label for="showService">{{'FIXED_PRICE.SERVICE' | translate}}</label>
        </div>
      </div>
    </div>
    <div *ngIf="showService" class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="service" class="col-2 col-form-label">{{'FIXED_PRICE.SERVICE' | translate}}</label>
        <div class="col-10">
          <input type="text" id="service" class="form-control" [(ngModel)]="_service" name="service" required/>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div *ngIf="!showService" class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="genArt" class="col-2 col-form-label">{{'FIXED_PRICE.GEN_ART' | translate}}</label>
        <div class="col-10">
          <input id="genArt" type="text" name="genArt"
                 class="form-control" #name="ngModel"
                 placeholder="{{'FIXED_PRICE.ALL_ARTICLES' | translate}}"
                 [ngbTypeahead]="dataService.searchGenArt"
                 [container]="'body'"
                 (selectItem)="setGenArt($event)"
                 [resultFormatter]="helperService.formatterDescriptionWithId"
                 [inputFormatter]="helperService.formatterDescriptionWithId"
                 [editable]='false'
                 [(ngModel)]="_genArt" required/>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="customId" class="col-2 col-form-label">{{'FIXED_PRICE.CUSTOM_ID' | translate}}</label>
        <div class="col-10">
          <input type="text" id="customId" class="form-control" [(ngModel)]="fixedPriceCalculation.customId"
                 name="customId" required/>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="unit" class="col-2 col-form-label">{{'FIXED_PRICE.UNIT' | translate}}</label>
        <div class="col-10">
          <select required type="text" name="unit" class="form-control" #name="ngModel" id="unit"
                  [(ngModel)]="fixedPriceCalculation.unit">
            <option value="" selected disabled hidden>{{'FORM.CHOOSE' | translate}}</option>
            <option *ngFor="let unit of calculationUnits"
                    [value]="unit">{{'FIXED_PRICE.UNITS.' + unit | translate | slice:3}}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="quantity" class="col-2 col-form-label">{{'FIXED_PRICE.QUANTITY' | translate}}</label>
        <div class="col-10">
          <input id="quantity" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="fixedPriceCalculation.quantity"
                 step="1" min="0" name="quantity" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="quantity" class="col-2 col-form-label">{{'MATERIAL_CODES.MC' | translate}}</label>
        <div class="col-10">
          <input id="material-code" type="text" class="form-control" #name="ngModel"
                 [(ngModel)]="fixedPriceCalculation.materialCode"
                 [disabled]="!showService"
                 name="material-code" >
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-primary font-weight-bold">
          {{'FIXED_PRICE.FLEET' |translate}}:
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fOp2" class="col-2 col-form-label">{{'FIXED_PRICE.OPERATION' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="fixedPriceCalculation.computationMap.Flotte.operation" name="fOp2"
                  id="fOp2">
            <option value="CONST_VALUE">{{'FIXED_PRICE.OPERATION_CONST_VALUE' | translate }}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="fvalue" class="col-2 col-form-label">{{'FIXED_PRICE.AMOUNT' | translate}}</label>
        <div class="col-10">
          <input id="fvalue" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="fixedPriceCalculation.computationMap.Flotte.value"
                 pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="fvalue" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-primary font-weight-bold">
          {{'FIXED_PRICE.GARAGE' |translate}}:
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="wOp2" class="col-2 col-form-label">{{'FIXED_PRICE.OPERATION' | translate}}</label>
        <div class="col-10">
          <select required type="text" class="form-control" #name="ngModel"
                  [(ngModel)]="fixedPriceCalculation.computationMap.Werkstatt.operation" name="wOp2"
                  id="wOp2">
            <option value="CONST_VALUE">{{'FIXED_PRICE.OPERATION_CONST_VALUE' | translate }}</option>
          </select>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
        <label for="wvalue" class="col-2 col-form-label">{{'FIXED_PRICE.AMOUNT' | translate}}</label>
        <div class="col-10">
          <input id="wvalue" type="number" class="form-control" #name="ngModel"
                 [(ngModel)]="fixedPriceCalculation.computationMap.Werkstatt.value"
                 pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="wvalue" required>
          <span class="show-required-star"></span>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-12">
          <input type="checkbox" class="toggle toggle-primary" id="showVatExempt"
                 [(ngModel)]="fixedPriceCalculation.vatExempt" name="showVatExempt">
          <label for="showVatExempt">{{'FIXED_PRICE.VAT_EXEMPT' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <input type="checkbox" class="toggle toggle-primary" id="allowCustomerPrice"
                 [(ngModel)]="fixedPriceCalculation.allowCustomerPrice" name="allowCustomerPrice">
          <label for="allowCustomerPrice">{{'FIXED_PRICE.ALLOW_CUSTOMER_PRICE' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12  button-group">
          <button type="submit" [disabled]="fixedPriceForm.form.invalid"
                  class="btn btn-primary float-left">{{'BTN.SAVE' | translate }}</button>
          <button type="button" (click)="helperService.locationBackfromMenu()"
                  class="btn btn-outline-secondary float-right">{{'BTN.CANCEL' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</form>
