<div class="header-title">
  <div class="breadcrumb">
    <ul class="list">
      <li class="item title-grey" (click)="fleetService.setCurrentSubmenu(); fleetService.setCurrentFleetById()"
          routerLink="/fleet">
        <i class="n4icon-home-outline"></i>
        {{'FLEET.OVERVIEW' | translate}}
      </li>
      <li class="item title-grey cursor-default" *ngIf="currentFleet" >
        -
        {{currentFleet.fleetname}}</li>
      <li class="item title-grey" *ngIf="currentSubmenu"
          [routerLink]="['/fleet/' + fleetService.fleetId.value + '/' + currentSubmenu.toLowerCase()]">
        -
        {{'FLEET.' + currentSubmenu | translate}}
      </li>
      <li class="item add-element" *ngIf="(!currentSubmenu && !currentFleet && !helperService.checkEditOrNewPage())"
          [routerLink]="['/fleet/new_fleet']">
        <i class="n4icon-plus-add-circle-outline"></i>
        {{'FLEET.NEW_FLEET' | translate}}
      </li>
      <li class="item add-element" *ngIf="checkUrlMenu('oe_part') && !helperService.checkEditOrNewPage()"
          [routerLink]="['/fleet/' + fleetService.fleetId.value + '/oe_part/new_oe_part_calculation']">
        <i class="n4icon-plus-add-circle-outline"></i>
        {{'OE_PART.CREATE_CALCULATION' | translate}}
      </li>
      <li class="item add-element" *ngIf="checkUrlMenu('fixed_price') && !helperService.checkEditOrNewPage()"
          [routerLink]="['/fleet/' + fleetService.fleetId.value + '/fixed_price/new_fixed_price_calculation']">
        <i class="n4icon-plus-add-circle-outline"></i>
        {{'FIXED_PRICE.NEW_FIXED_PRICE' | translate}}
      </li>
      <li class="item add-element" *ngIf="checkUrlMenu('after_market') && !helperService.checkEditOrNewPage()"
          [routerLink]="['/fleet/' + fleetService.fleetId.value + '/after_market/new_aftermarket_calculation']">
        <i class="n4icon-plus-add-circle-outline"></i>
        {{'AFTERMARKET.CREATE_CALCULATION' | translate}}
      </li>
      <li class="item add-element" *ngIf="checkUrlMenu('material_codes') && !helperService.checkEditOrNewPage()"
          [routerLink]="['/fleet/' + fleetService.fleetId.value + '/material_codes/new_material_codes']">
        <i class="n4icon-plus-add-circle-outline"></i>
        {{'MATERIAL_CODES.CREATE_MATERIAL_CODE' | translate}}
      </li>
    </ul>
  </div>
</div>
