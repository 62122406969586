<div class="d-block {{level}}"
     [ngClass]="[show? 'text-dark': 'text-secondary', (item.children&& show)? 'mb-3': 'no-children', (level > 0)? 'ml-5' : 'ml-0']">
  <div class="d-flex align-items-center cursor-pointer">
    <i (click)="$event.stopPropagation(); show= !show" *ngIf="item.children"
       class="n4icon-arrow-right-next-outline"></i>
    <span (click)="$event.stopPropagation(); show= !show">{{item.name}}</span>
    <span *ngIf="show" class=" text-primary d-flex align-items-center ml-5" (click)="setGenArts(item); open(content)">  <i
      class="font-size-1rem n4icon-plus-add-circle-outline"></i> {{'BTN.ADD' | translate}}</span>
  </div>
  <div *ngIf="item.children && show">
    <div *ngFor="let children of item.children">
      <app-tree-item [mcList]="mcList" [item]="children" [level]="level + 1" [show]="false"></app-tree-item>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <form  #mcForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'BTN.NEW_MATERIAL_CODE' | translate}}</h4>
      <button type="button" class="close no-outline" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body material-code-modal">
      <div class="row">
        <div class="col-12">
          <div class="form-group row">
            <label for="mc" class="col-2 col-form-label">{{'MATERIAL_CODES.MC' | translate}}</label>
            <div class="col-10">
              <input id="mc" type="text" name="mc"
                     [(ngModel)]="materialCode"
                     class="form-control"
                     required/>
              <span class="show-required-star"></span>
            </div>
          </div>
        </div>
        <div class="col-6">
          <b class="mb-3">{{'MATERIAL_CODES.GEN_ART_ALL' | translate}}</b> <br>
          <ol>
              <li *ngFor="let genart of allArticles;">
                <span>{{genart.description}} ({{genart.id}})</span>
              </li>
            </ol>
        </div>
        <div class="col-6">
          <b class="mb-3">{{'MATERIAL_CODES.GEN_ART_WITHOUT_MC' | translate}}</b> <br>
          <ol>
            <li *ngFor="let genart of selectedWithoutMC;">
              <span>{{genart.description}} ({{genart.id}})</span>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary"
              (click)="modal.close('Save click')">{{'BTN.CANCEL' | translate}}</button>
      <button type="button" class="btn btn-primary" [disabled]="mcForm.invalid"
              (click)="modal.close('Save click'); addToMaterialCode('without-mc')">{{'BTN.ADD_ONLY_ARTICLE_WITHOUT_MC' | translate}}</button>
      <button type="button" class="btn btn-primary"  [disabled]="mcForm.invalid"
              (click)="modal.close('Save click'); addToMaterialCode('all')">{{'BTN.ADD_ALL' | translate}}</button>
    </div>
  </form>
</ng-template>
