import {Component, OnDestroy, OnInit} from '@angular/core';
import {IFleet} from '../shared/interfaces/fleet.interfaces';
import {Subscription} from 'rxjs';
import {FleetService} from '../shared/services/fleet.service';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html'
})
export class FleetComponent implements OnInit, OnDestroy {
  public currentFleet: IFleet;
  public fleetSubscription: Subscription;

  constructor(private fleetService: FleetService) {
  }

  ngOnInit() {
    this.fleetSubscription = this.fleetService.currentFleet.subscribe(
      data => this.currentFleet = data);
  }

  ngOnDestroy() {
    if (this.fleetSubscription) {
      this.fleetSubscription.unsubscribe();
    }
  }

}


