import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CalculationService} from '../../../../shared/services/calculation.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {CalculationRestService} from '../../../../shared/services/rest-services/calculation-rest.service';
import {DefenitionRestService} from '../../../../shared/services/rest-services/defenition-rest.service';
import {FleetService} from '../../../../shared/services/fleet.service';
import {Location} from '@angular/common';
import {ICalculation} from '../../../../shared/interfaces/calculation.interface';
import {DataService} from '../../../../shared/services/data.service';
import {Subscription} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import {ToastService} from '../../../../shared/services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {IGenArt} from '../../../../shared/interfaces/data.interface';

@Component({
  selector: 'app-new-fixed-price-calculation',
  templateUrl: './new-fixed-price-calculation.component.html'
})
export class NewFixedPriceCalculationComponent implements OnInit, OnDestroy {
  public currentId: number;
  public fixedPriceCalculation: ICalculation;
  public calculationUnits: string[];
  public calculationOperations: string[];
  public routeSubscription: Subscription;
  public showService = false;
  public _genArt: IGenArt;
  public _service;

  constructor(private route: ActivatedRoute,
              private calculationService: CalculationService,
              private calculationRestService: CalculationRestService,
              private defenitionRestService: DefenitionRestService,
              public dataService: DataService,
              private fleetService: FleetService,
              private location: Location,
              private translate: TranslateService,
              private toastService: ToastService,
              public helperService: HelperService) {

    this.fixedPriceCalculation = {
      id: null,
      type: 'FIXED_PRICE',
      sortNo: 1,
      fleetId: null,
      description: '',
      active: true,
      unit: '',
      vatExempt: false,
      customId: '',
      materialCode: '',
      quantity: null,
      conditions: [{
        sortNo: 1,
        conditionField: 'GEN_ART',
        value: null
      },
        {
          sortNo: 2,
          conditionField: 'CUSTOM_POS',
          value: ''
        }
      ],
      computationMap: {
        Flotte: {
          operation: 'CONST_VALUE',
          value: null
        },
        Werkstatt: {
          operation: 'CONST_VALUE',
          value: null
        }
      },
      allowCustomerPrice: false,
    };
  }

  ngOnInit() {
    this.setFleedId();
    this.dataService.getGenArts();
    this.getDefenitions();

    this.routeSubscription = this.route.parent.params.subscribe((params) => {
      this.currentId = Number(params.calcId);
      if (this.currentId > 0) {
        this.calculationService.setCurrentCalculationById(this.currentId);
        this.calculationService.currentCalculation.pipe(debounceTime(500), take(1)).subscribe(data => {
          this.fixedPriceCalculation = data;
          this.setConditionsToModel();
        });
      }
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  public setFleedId() {
    this.fixedPriceCalculation.fleetId = this.fleetService.fleetId.value;
  }


  public getDefenitions() {
    this.defenitionRestService.getDefenitionsByMethodeForType('calculation_operations', 'FIXED_PRICE').subscribe(data => this.calculationOperations = data);
    this.defenitionRestService.getDefenitionsByMethodeForType('calculation_units', 'FIXED_PRICE').subscribe(data => this.calculationUnits = data);
  }


  public setGenArt(event) {
    if (!this.fixedPriceCalculation.customId) {
      this.fixedPriceCalculation.customId = event.item.id.toString();
    }
    if (this.fixedPriceCalculation.conditions.some((element => element.conditionField === 'GEN_ART'))) {
      this.fixedPriceCalculation.conditions[this.searchIndex('GEN_ART')].value = event.item.id.toString();
    } else {
      this.fixedPriceCalculation.conditions.push({
        sortNo: 1,
        conditionField: 'GEN_ART',
        value: event.item.id.toString()
      });
    }
  }

  public serviceChanged() {
    if (this.showService) {
      this._genArt = null;
      let found = false;
      for (const condition of this.fixedPriceCalculation.conditions) {
        if (condition.conditionField === 'CUSTOM_POS') {
          found = true;
          this.fixedPriceCalculation.conditions[this.searchIndex('CUSTOM_POS')].value = this._service;
        }
      }
      if (!found) {
        this.fixedPriceCalculation.conditions.push({
          sortNo: 1,
          conditionField: 'CUSTOM_POS',
          value: this._service
        });
      }
    } else {
      this._service = null;
    }
  }

  public searchIndex(type: string): number {
    return this.fixedPriceCalculation.conditions.findIndex(item => item.conditionField === type);
  }

  public setConditionsToModel() {
    this.fixedPriceCalculation.conditions.forEach((condition) => {
      if (condition.conditionField === 'GEN_ART') {
        this._genArt = {
          id: Number(condition.value),
          description: this.dataService.genArts[this.dataService.genArts.findIndex(item => item.id === Number(String(condition.value)))].description
        };
        this.showService = false;
      }
      if (condition.conditionField === 'CUSTOM_POS') {
        this._service = condition.value;
        this.showService = true;
      }
    });
  }

  public setConditionsFromModel() {
    if (this._service && this.showService) {
      this.fixedPriceCalculation.conditions[this.searchIndex('CUSTOM_POS')].value = this._service;
    } else if (this.searchIndex('CUSTOM_POS') > -1) {
      this.fixedPriceCalculation.conditions.splice(this.searchIndex('CUSTOM_POS'), 1);
    }
    if (this._genArt && !this.showService) {
      this.fixedPriceCalculation.conditions[this.searchIndex('GEN_ART')].value = this._genArt.id.toString();
    } else if (this.searchIndex('GEN_ART') > -1) {
      this.fixedPriceCalculation.conditions.splice(this.searchIndex('GEN_ART'), 1);
    }
  }


  public saveFixedPriceCalculation() {
    this.fixedPriceCalculation.description = (this._service ? this._service : (this._genArt.description + '(' + this._genArt.id + ')'));
    this.setConditionsFromModel();
    if (this.checkDuplicateGenArtByNewCalculation()) {
      this.toastService.show(this.translate.instant('FIXED_PRICE.MESSAGE_DUPLICATE'), {
        classname: 'bg-warning text-light',
        delay: 5000
      });
    } else {
      const request = this.currentId ? this.calculationRestService.updateCalculation(this.fixedPriceCalculation as ICalculation) : this.calculationRestService.createCalculation(this.fixedPriceCalculation as ICalculation);
      request.subscribe(() => {
        this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'FIXED_PRICE');
        this.location.back();
      });
    }
  }

  public checkDuplicateGenArtByNewCalculation(): boolean {
    if (this._service) {
      return false;
    }
    if (this.currentId > 0) {
      return false;
    } else {
      if (this.calculationService.calculations.value) {
        const result = this.calculationService.calculations.value
          .filter((element) => element.type === 'FIXED_PRICE' && element.description === (document.getElementById('genArt') as HTMLInputElement).value);
        return result && result.length > 0;
      }
    }
  }

}
