import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHourlyWageCalculation } from '../interfaces/hourly-wage.interfaces';
import { CalculationRestService } from './rest-services/calculation-rest.service';
import { ICalculation } from '../interfaces/calculation.interface';
import { shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CalculationService {
  public hourlyWageCalcIdId: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  public currentHourlyWageCalculation: BehaviorSubject<IHourlyWageCalculation>;
  public hourlyWageParts: BehaviorSubject<IHourlyWageCalculation[]> = new BehaviorSubject<IHourlyWageCalculation[]>(null);
  public calcIdId: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  public currentCalculation: BehaviorSubject<ICalculation>;
  public calculations: BehaviorSubject<ICalculation[]> = new BehaviorSubject<ICalculation[]>(null);


  constructor(private calculationRestService: CalculationRestService) {
    this.currentCalculation = new BehaviorSubject<ICalculation>(undefined);
    this.calcIdId.pipe(
      shareReplay(1)
    ).subscribe(data => {
      this.currentCalculation.next(null);
      if (data) {
        this.calculationRestService.getCalculationById(data).subscribe(data => {
          this.currentCalculation.next(data);
        });
      }
    });

    this.currentHourlyWageCalculation = new BehaviorSubject<IHourlyWageCalculation>(undefined);
    this.hourlyWageCalcIdId.subscribe(data => {
      if (data) {
        this.calculationRestService.getHourlyWageCalculationById(data).subscribe(data => {
          this.currentHourlyWageCalculation.next(data);
        });
      } else {
        this.currentHourlyWageCalculation.next(null);
      }

    });
  }

  public resetCalculationIds() {
    this.calcIdId.next(null);
    this.hourlyWageCalcIdId.next(null);
  }


  public getCalculationsByFleetAndType(fleetId: number, type: string) {
    this.calculationRestService.getCalculationsByFleetAndType(fleetId, type).subscribe((response) => {
      if (response) {
        this.calculations.next(response);
      } else {
        this.calculations.next(null);
      }
    });
  }


  public setCurrentCalculationById(id?: any) {
    if (!id) {
      this.calcIdId.next(null);
    }
    if (id !== this.calcIdId.value) {
      this.calcIdId.next(id);
    }
  }


  public getHourlyWagePartsByFleet(fleetId: number) {
    this.calculationRestService.getWagesByFleet(fleetId).subscribe((response) => {
      this.hourlyWageParts.next(response);
    });
  }


  public setCurrentHourlyWageCalculationById(id?: number) {
    if (!id) {
      this.hourlyWageCalcIdId.next(null);
    }
    if (id !== this.hourlyWageCalcIdId.value) {
      this.hourlyWageCalcIdId.next(id);
    }
  }
}
