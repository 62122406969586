import {Component, OnDestroy, OnInit} from '@angular/core';
import {FleetService} from '../../shared/services/fleet.service';
import {Subscription} from 'rxjs';
import {IFleet} from '../../shared/interfaces/fleet.interfaces';

@Component({
  selector: 'app-fleet-overview',
  templateUrl: './fleet-overview.component.html'
})
export class FleetOverviewComponent implements OnInit, OnDestroy {
  public fleets: IFleet[];
  public submenus: string[];
  public fleetSubscription: Subscription;
  public submenuSubscription: Subscription;

  constructor(public fleetService: FleetService) {

  }

  ngOnInit() {
    this.submenuSubscription = this.fleetService.getFleetSubmenu().subscribe(
      data => this.submenus = data);

    this.fleetSubscription = this.fleetService.fleets.subscribe(
      fleets => this.fleets = fleets);
  }

  ngOnDestroy() {
    this.submenuSubscription.unsubscribe();
    this.fleetSubscription.unsubscribe();
  }
}
