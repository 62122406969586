import { Injectable } from '@angular/core';
import { FleetService } from './fleet.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public showUserDropdown: boolean = false;

  constructor(private fleetService: FleetService,
              private userService: UserService,
              private location: Location,
              private router: Router) {

  }

  public checkEditOrNewPage(): boolean {
    return (this.router.url.includes('/new_') || this.router.url.includes('/edit_'));
  }

  public locationBackfromMenu(menu?: boolean) {
    if (menu) {
      this.fleetService.setCurrentSubmenu();
      this.router.navigate(['/fleet/' + this.fleetService.fleetId.value]);
    } else {
      this.location.back();
    }
  }

  public getCurrencySmbol() {
    return this.userService?.userData?.currency;
  }

  public formatterDescriptionWithId = (x) => x.description + ' (' + x.id + ')';

  public formatterDescription = (x) => x.description;

  public formatterName = (x: { name: string }) => x.name;

}
