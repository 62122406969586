<ng-container>
  <div class="mb-5 mt-3">
    <button type="button" class="btn mr-3"
            [ngClass]="showTab === 'list-material-codes'? 'btn-primary': 'btn-outline-primary'"
            (click)="showTab='list-material-codes'">
      {{'MATERIAL_CODES.TITLE' | translate}}
    </button>

    <button type="button" class="btn mr-3"
            [ngClass]="showTab === 'tree-material-codes'? 'btn-primary': 'btn-outline-primary'"
            (click)="showTab='tree-material-codes'">
      {{'MATERIAL_CODES.ARTICLE_VIEW' | translate}}
    </button>
  </div>
</ng-container>


<ng-container *ngIf="showTab==='tree-material-codes'">
  <div *ngFor="let item of dataService.tree$ | async">
    <div class="element-box d-block">
      <app-tree-item [mcList]="materialCodes" [item]="item" [level]="0" [show]="false"></app-tree-item>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="materialCodes.length >0 && showTab==='list-material-codes'">
  <div class="custom-list">
    <div class="list-item list-header">
      <div class="row">
        <div class="col">
          {{'GENERAL.DESCRIPTION' | translate}}
        </div>
        <div class="col-3 text-right">
          {{'GENERAL.OPTIONS' | translate}}
        </div>
      </div>
    </div>
    <div *ngFor="let materialCode of materialCodes" class="list-item">
      <div class="row">
        <div class="col">
          {{materialCode[0].materialCode }}
        </div>
        <div class="col-3 text-right">
          <i class="n4icon-edit-pen-outline m-2"
             [routerLink]="['/fleet/' + fleetService.fleetId.value + '/material_codes/' + materialCode[0].materialCode + '/edit_material_codes']">
          </i>
          <i (click)="open(content); deletableMC= materialCode[0].materialCode" class="n4icon-trash-outline m-2">
          </i>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!materialCodes || materialCodes.length== 0">
  <div class="element-box min-h-2 w-100 m-0 mt-3">
    <span class="empty-element">{{'GENERAL.NO_RESULTS' | translate}}</span>
  </div>
</ng-container>

<div class="button-group">
  <button type="submit" class="btn btn-outline-secondary float-right ml-2"
          (click)="helperService.locationBackfromMenu(true)">
    {{'BTN.BACK' | translate }}</button>
  <button type="submit" class="btn btn-primary float-right ml-2"
          [routerLink]="['/fleet/' + fleetService.fleetId.value + '/material_codes/new_material_codes']">
    {{'BTN.NEW_MATERIAL_CODE' | translate }}</button>
  <button type="submit" class="btn btn-primary float-right ml-2"
          (click)="csvDownload()">
    {{ 'BTN.CSV_DOWNLOAD' | translate }}
  </button>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'MATERIAL_CODES.DELETE_MATERIAL_CODE' | translate}}</h4>
    <button type="button" class="close no-outline" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">{{'BTN.CANCEL' | translate}}</button>
    <button type="button" class="btn btn-danger"
            (click)="modal.close('Save click'); deleteMaterialCode()">{{'BTN.DELETE_MATERIAL_CODE' | translate}}</button>
  </div>
</ng-template>
