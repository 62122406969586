import {Component, OnDestroy, OnInit} from '@angular/core';
import {FleetService} from '../../../shared/services/fleet.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import {CalculationService} from '../../../shared/services/calculation.service';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-hourly-wage',
  templateUrl: './hourly-wage.component.html'
})
export class HourlyWageComponent implements OnInit, OnDestroy {
  public currentId: number;
  public Subscription: Subscription;

  constructor(private fleetService: FleetService,
              private route: ActivatedRoute,
              private location: Location,
              private router: Router,
              private calculationService: CalculationService) {
    // if user navigates from 1 fleet to another  and naviagtes to the same component, NgOnit would not be triggered. solution via routing events and startUp call
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((url) => {
      this.getCurrentId();
      if (this.currentId != this.fleetService.fleetId.value && url['url'].split('/').pop() === 'hourly_wage') {
        this.startUpCheck();
      }
    })
  }

  ngOnInit() {
    this.startUpCheck();
    this.Subscription = this.fleetService.currentSubmenu.subscribe(submenu => {
      if (submenu !== 'HOURLY_WAGE') {
        this.fleetService.currentSubmenu.next('HOURLY_WAGE');
      }
    });
  }

  ngOnDestroy() {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
    this.fleetService.currentSubmenu.next(null);
  }

  public startUpCheck() {
    this.getCurrentId();
    if (this.currentId > 0) {
      this.fleetService.setCurrentFleetById(this.currentId);
      this.calculationService.getHourlyWagePartsByFleet(this.currentId);
    }
  }

  public getCurrentId() {
    const url = this.router.url.split('/');
    this.currentId = Number(url[2]);
  }
}
