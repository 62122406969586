import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CalculationService} from '../../../../shared/services/calculation.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {FleetService} from '../../../../shared/services/fleet.service';
import {ICalculation} from '../../../../shared/interfaces/calculation.interface';
import {CalculationRestService} from '../../../../shared/services/rest-services/calculation-rest.service';
import {DefenitionRestService} from '../../../../shared/services/rest-services/defenition-rest.service';
import {DataService} from '../../../../shared/services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import {IDlnr, IGenArt} from '../../../../shared/interfaces/data.interface';

@Component({
  selector: 'app-new-aftermarket-calculation',
  templateUrl: './new-aftermarket-calculation.component.html'
})
export class NewAftermarketCalculationComponent implements OnInit, OnDestroy {
  public currentId: number;
  public aftermarketCalculation: ICalculation;
  public calculationUnits: string[];
  public routeSubscription: Subscription;
  public calculationOperations: string[];
  public _genArt: IGenArt;
  public _dlnr: IDlnr;
  public validGenArt = true;
  public validDlnr = true;

  constructor(private route: ActivatedRoute,
              private calculationService: CalculationService,
              private calculationRestService: CalculationRestService,
              private defenitionRestService: DefenitionRestService,
              public dataService: DataService,
              private fleetService: FleetService,
              private router: Router,
              private translate: TranslateService,
              public helperService: HelperService) {


    this.aftermarketCalculation = {
      id: null,
      type: 'AFTER_MARKET',
      sortNo: 1,
      fleetId: null,
      description: '',
      active: true,
      unit: '',
      materialCode: '',
      quantity: null,
      conditions: [],
      computationMap: {
        Flotte: {
          operation: '',
          value: null
        },
        Werkstatt: {
          operation: '',
          value: null
        }
      }
    };

  }

  ngOnInit() {
    this.setFleedId();
    this.getData();
    this.getDefinitions();

    this.routeSubscription = this.route.parent.params.subscribe((params) => {
      this.currentId = Number(params.calcId);
      if (this.currentId > 0) {
        this.calculationService.setCurrentCalculationById(this.currentId);
        this.calculationService.currentCalculation.pipe(debounceTime(500), take(1)).subscribe(data => {
          this.aftermarketCalculation = data;
          this.setConditionsToModel();
          this.changeOperations();
        });
      }
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }


  public setConditionsToModel() {
    this.aftermarketCalculation.conditions.forEach((condition, i) => {
      if (condition.conditionField === "GEN_ART") {
        const temp = this.dataService.genArts[this.dataService.genArts.findIndex(item => item.id === parseInt(String(condition.value)))];
        const description = temp ? temp.description : '';
        this._genArt = {
          id: Number(this.aftermarketCalculation.conditions[i].value),
          description: description
        };
      }
      if (condition.conditionField === "BRAND") {
        const temp = this.dataService.dlnrs[this.dataService.dlnrs.findIndex(item => item.id === parseInt(String(condition.value)))];
        const name = temp ? temp.name : '';
        this._dlnr = {
          id: Number(this.aftermarketCalculation.conditions[i].value),
          name: name
        };
      }
    });
  }

  public setConditionsFromModel() {
    if (this.aftermarketCalculation && this.aftermarketCalculation.conditions) {
      if (this._dlnr) {
        this.conditionExists('BRAND') ?
          this.aftermarketCalculation.conditions[this.searchIndex('BRAND')].value = this._dlnr.id.toString() :
          this.aftermarketCalculation.conditions.push({
            sortNo: 1,
            conditionField: 'BRAND',
            value: this._dlnr.id.toString()
          });
      } else {
        this.aftermarketCalculation.conditions.splice(this.searchIndex('BRAND'), 1);
      }
      if (this._genArt) {
        this.conditionExists('GEN_ART') ?
          this.aftermarketCalculation.conditions[this.searchIndex('GEN_ART')].value = this._genArt.id.toString() :
          this.aftermarketCalculation.conditions.push({
            sortNo: 2,
            conditionField: 'GEN_ART',
            value: this._genArt.id.toString()
          });
      } else {
        this.aftermarketCalculation.conditions.splice(this.searchIndex('GEN_ART'), 1);
      }
    }
  }


  public setFleedId() {
    this.aftermarketCalculation.fleetId = this.fleetService.fleetId.value;
  }


  public getDefinitions() {
    this.defenitionRestService.getDefenitionsByMethodeForType('calculation_operations', 'AFTER_MARKET').subscribe(data => this.calculationOperations = data);
    this.defenitionRestService.getDefenitionsByMethodeForType('calculation_units', 'AFTER_MARKET').subscribe(data => this.calculationUnits = data);
  }

  public getData() {
    this.dataService.getGenArts();
    this.dataService.getDlnrs();
  }

  public setGenArt(event) {
    this.validGenArt = true;
    this._genArt = event.item;
  }

  public setDlnr(event) {
    this.validDlnr = true;
    this._dlnr = event.item;
  }


  public checkValidEntryGenArt(e) {
    const userInput = e.target.value;
    if (!this.dataService.genArts.some(item => userInput.includes(item.description))) {
      this.validGenArt = false;
    }
    if (userInput === '') {
      this.validGenArt = true;
    }
  }

  public checkValidEntryDlnr(e) {
    const userInput = e.target.value;
    if (!this.dataService.dlnrs.some(item => userInput.includes(item.name))) {
      this.validDlnr = false;
    }
    if (userInput === '') {
      this.validDlnr = true;
    }
  }


  public setDescription() {
    if (this._dlnr) {
      this.aftermarketCalculation.description = this._dlnr.name;
    } else {
      this.aftermarketCalculation.description = this.translate.instant('AFTERMARKET.ALL_DLNRS');
    }
    if (this._genArt) {
      this.aftermarketCalculation.description += ' - ' + this._genArt.description + '(' + this._genArt.id + ')';
    } else {
      this.aftermarketCalculation.description += ' - ' + this.translate.instant('AFTERMARKET.ALL_ARTICLES');
    }
  }

  public setOperations() {
    this.aftermarketCalculation.computationMap.Flotte.operation =
      (document.getElementsByName('fOp1')[0] as HTMLInputElement).value + '_' +
      (document.getElementsByName('fOp2')[0] as HTMLInputElement).value;
    this.aftermarketCalculation.computationMap.Werkstatt.operation =
      (document.getElementsByName('wOp1')[0] as HTMLInputElement).value + '_' +
      (document.getElementsByName('wOp2')[0] as HTMLInputElement).value;

    if (this.aftermarketCalculation.computationMap.Flotte.operation === 'CONST_CHARGE') {
      this.aftermarketCalculation.computationMap.Flotte.operation = 'ADDITIONAL_CHARGE';
    }
    if (this.aftermarketCalculation.computationMap.Werkstatt.operation === 'CONST_CHARGE') {
      this.aftermarketCalculation.computationMap.Werkstatt.operation = 'ADDITIONAL_CHARGE';
    }
  }

  public changeOperations() {
    if (this.aftermarketCalculation.computationMap.Flotte.operation === 'ADDITIONAL_CHARGE') {
      this.aftermarketCalculation.computationMap.Flotte.operation = 'CONST_CHARGE';
    }
    if (this.aftermarketCalculation.computationMap.Werkstatt.operation === 'ADDITIONAL_CHARGE') {
      this.aftermarketCalculation.computationMap.Werkstatt.operation = 'CONST_CHARGE';
    }
  }


  public searchIndex(type: string): number {
    return this.aftermarketCalculation.conditions.findIndex(item => item.conditionField === type);
  }


  public saveAftermarketCalculation() {
    this.setDescription();
    this.setConditionsFromModel();
    this.setOperations();

    if (this.currentId) {
      this.calculationRestService.updateCalculation(this.aftermarketCalculation as ICalculation).subscribe(() => {
        this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'AFTER_MARKET');
      });
    } else {
      this.calculationRestService.createCalculation(this.aftermarketCalculation as ICalculation).subscribe(
        () => {
          this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'AFTER_MARKET');
          setTimeout(() => {
            const calculations = [];
            if (this.calculationService.calculations) {
              this.calculationService.calculations.value.forEach(elem => {
                calculations.push(elem);
              });
            }
            const mapped = calculations.map(calc => {
              return {id: calc.id, sortNo: calc.sortNo + 1};
            });
            this.calculationRestService.sortCalculation(this.fleetService.fleetId.value, mapped).subscribe();
          }, 250);
        });
    }
    this.router.navigate(['/fleet/' + this.fleetService.fleetId.value + '/after_market']);
  }

  public conditionExists(type: string): boolean {
    return this.searchIndex(type) >= 0;
  }
}
