import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { HelperService } from '../shared/services/helper.service';
import { Router } from '@angular/router';
import { FleetService } from '../shared/services/fleet.service';
import {UserService} from '../shared/services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  host: {
    '(document:click)': 'onOutsideClick($event)',
  }
})
export class NavbarComponent implements OnInit {
  public showUserDropDown = false;


  constructor(private authService: AuthService,
              private router: Router,
              private _eref: ElementRef,
              public helperService: HelperService,
              public fleetService: FleetService,
              public userService: UserService) {
  }

  ngOnInit() {
  }

  public toggleUserDropdown(event: any) {
    this.showUserDropDown = !this.showUserDropDown;
  }

  public logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });

  }

  private onOutsideClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.showUserDropDown = false;
    }
  }
}
