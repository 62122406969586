<form name="form" class="mt-3" (ngSubmit)="hourlyWageForm.form.valid && saveHourlyWageCalculation()" #hourlyWageForm="ngForm">
  <div class="row no-gutters data-box" *ngIf="hourlyWageCalculation">
    <div class="col-12">
      <div class="row">
        <div class="col-12 mb-3 font-weight-bold">
          {{'HOURLY_WAGE.TITLE' |translate}}: {{'HOURLY_WAGE.PARTS.' + hourlyWageCalculation.type | translate}}
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-primary font-weight-bold">
          {{'HOURLY_WAGE.FLEET' |translate}}:
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
      <label for="fvalue" class="col-2 col-form-label">{{'HOURLY_WAGE.CURRENCY' | translate}}</label>
        <div class="col-10">
        <input id="fvalue" type="number" class="form-control" #name="ngModel"
               [(ngModel)]="hourlyWageCalculation.amountDTOMap.Flotte.amount"
               pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="fvalue" required>
        <span class="show-required-star"></span>
      </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
      <label for="funit" class="col-2 col-form-label">{{'OE_PART.UNIT' | translate}}</label>
      <div class="col-10">
        <select required type="text" class="form-control" id="funit" name="funit" #fUnit="ngModel"
                [(ngModel)]="hourlyWageCalculation.amountDTOMap.Flotte.unit">
          <option *ngFor="let unit of hourlyWageUnits"
                  [value]="unit">{{'HOURLY_WAGE.UNITS.' + unit | translate | slice:3}}</option>
        </select>
        <span class="show-required-star"></span>
      </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-primary font-weight-bold">
          {{'HOURLY_WAGE.GARAGE' |translate}}:
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div class="form-group row">
      <label for="wvalue" class="col-2 col-form-label">{{'HOURLY_WAGE.CURRENCY' | translate}}</label>
      <div class="col-10">
        <input id="wvalue" type="number" class="form-control" #name="ngModel"
               [(ngModel)]="hourlyWageCalculation.amountDTOMap.Werkstatt.amount"
               pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="wvalue" required>
        <span class="show-required-star"></span>
      </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 ">
      <div class="form-group row">
      <label for="wunit" class="col-2 col-form-label">{{'OE_PART.UNIT' | translate}}</label>
      <div class="col-10">
        <select required type="text" class="form-control" id="wunit" name="wunit" #fUnit="ngModel"
                [(ngModel)]="hourlyWageCalculation.amountDTOMap.Werkstatt.unit">
          <option *ngFor="let unit of hourlyWageUnits"
                  [value]="unit">{{'HOURLY_WAGE.UNITS.' + unit | translate | slice:3}}</option>
        </select>
        <span class="show-required-star"></span>
      </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12  button-group">
          <button type="submit"
                  class="btn btn-primary float-left">{{'BTN.SAVE' | translate }}</button>
          <button type="button" (click)="helperService.locationBackfromMenu()"
                  class="btn btn-outline-secondary float-right">{{'BTN.CANCEL' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</form>
